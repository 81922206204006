import React from 'react'
//redux
import {useDispatch} from 'react-redux'
import { R4pages } from '../redux/action';
//import Styles
import style from './page2.module.css'
//component
import CurentTime from '../5component-CurentTime/CurentTime';
import UserDetails from '../6component-userDetails/UserDetails';
import DailyText from '../7component-dailyText/DailyText';
import PageKey from '../8component-pageKey/PageKey';
//import Images
import img1 from './../img/page2BG1.png'
import img2 from './../img/page2BG2.png'
import img3 from './../img/page2BG3.png'






//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function Page2() {

//=====================================================
// go azmounak---------------------------------
//=====================================================
const dispatch=useDispatch()
const openPage3Func=()=>{
    dispatch(R4pages(3,""))
}
//=====================================================
// go pishAzmun---------------------------------
//=====================================================
const openPage4Func=()=>{
    dispatch(R4pages(4,""))
}
//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.Container1} style={{ backgroundImage: `url(${img1})` }}>
            <div className={style.ContainerOver}>
                <div className={style.Container2} >
                    <div className={style.headerContain}>
                        <div className={style.right}>
                            <CurentTime/>
                            <UserDetails/>
                        </div>
                        <div className={style.left}>
                            <DailyText/>
                            <PageKey/>
                        </div>
                    </div>
                    <div className={style.contentContain}>
                        <div className={style.contentRight} >
                            <div className={`${style.Page2Item} animate__animated animate__lightSpeedInRight`} style={{ backgroundImage: `url(${img2})` }} onClick={openPage3Func}>
                                آزمونک
                            </div>
                            <div className={`${style.Page2Item} animate__animated animate__lightSpeedInRight`} style={{ backgroundImage: `url(${img3})` }} onClick={openPage4Func}>
                                پیش آزمون
                            </div>
                        </div>
                        <div className={`${style.contentLeft} animate__animated animate__lightSpeedInRight`} >
                            <h1>بانک آزمون ویژه دانش آموزان</h1>
                            <p>
                            نرم افزار کاربردی پژواک درگاهی ویژه برای دانش آموزان در جهت سهولت تست زنی و رویارویی با سبک های نوین تست زنی و صرفه ی بسیار زیاد مالی برای آن ها می باشد. با توجه به قرارگیری بیش از 100 هزار تست در نرم افزار و امکان طراحی آزمون های تستیِ آموزشی و سنجشی توسط دانش آموز و بررسی پیشرفت نموداری و درصدی این آزمون ها و گزارش آن ها به مشاور درگاهی قدرتمند در اختیار دانش آموزان قرار می گیرد.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================