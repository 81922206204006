import React,{useRef} from 'react';
//redux
import {useDispatch,useSelector} from 'react-redux'
import {R2alert,R12activePrint} from './../redux/action';

//import Styles
import style from './otherPrint.module.css'

import { ComponentToPrint1 } from '.././19component-pdf2/PdfRender2';
// print
import ReactToPrint from 'react-to-print';
//=====================================================
//=====================================================
//=====================================================
//component start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function OtherPrint() {
const componentRef = useRef();

//=====================================================
// ok alert function---------------------------------
//=====================================================
const dispatch=useDispatch()
const okAlert=()=>{
    dispatch(R12activePrint(false,undefined))
}
//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.Container} >
            <div className={`${style.alertContainer} animate__animated animate__bounceInRight`}>
                
                <ReactToPrint
                    trigger={() => <button className={style.printBtn}>پرینت</button>}
                    content={() => componentRef.current}
                />
                <div style={{display:"none"}}>
                    <ComponentToPrint1 ref={componentRef}  />
                </div>   
                <button type="" onClick={okAlert} className={style.botton}>لغو</button>
            </div>

        </div>
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================