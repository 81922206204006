export const azmoun = (state={
                                questionList:[],
                                serverAnswerList:[],
                                sendToServerList:[],
                                azmounKind:0,
                                pishAzmounDetails:{},
                                azmounAkDetails:{},
                                nightTestDetails:{},
                                startDate:{},
                                startTime:"",
                                endDate:{},
                                endTime:"",
                                activeQuestion:1,
                                azmounStatus:0,
                                answerNum:0,
                                noAnsverNum:0,
                                trueAnswerNum:0,
                                falseAnswerNum:0,
                                showResult:false
                            }, action) => {
 //=================================================                           
    switch (action.type) {
        case 'R5_SET_QUESTION':
            let tempListQ=[];
            let num=1;
            console.log(action.questionList)
            action.questionList.forEach(item=>{
                    tempListQ.push({number:num,...item,colorStatus:0,javab:0})
                    num=num+1;
            })
            return {...state,questionList:tempListQ}
        case 'R5_SET_AZMOUNKIND':
            return {...state,azmounKind:action.kind,pishAzmounDetails:action.pishD,azmounAkDetails:action.azmounakD,nightTestDetails:action.nightD}
        case 'R5_SET_STARTAZMOUN':
            return{...state,startDate:action.date,startTime:action.startTime,azmounStatus:true}
        case 'R5_SET_ENDAZMOUN':
            let tempAnsNum=0;
            let tempNoAnsNum=0;
            let tempTrueNum=0;
            let tempFalseNum=0;
            let sendtemplist=[];
            state.questionList.forEach(item=>{
               console.log("ok1") 
                if(item.javab===0){
                    console.log("noAnswer")
                    tempNoAnsNum+=1;
                    sendtemplist.push({id:item.id,answer:0}) 
                }else{
                    if(item.javab===item.time-49){
                        console.log("true")
                        tempTrueNum+=1;
                        sendtemplist.push({id:item.id,answer:1}) 
                    }else{
                        console.log("false")
                        tempFalseNum+=1; 
                        sendtemplist.push({id:item.id,answer:2}) 
                    }
                    tempAnsNum+=1;
                }
            })
            return{...state,
                endDate:action.date,
                sendToServerList:sendtemplist,
                azmounStatus:false,
                answerNum:tempAnsNum,
                noAnsverNum:tempNoAnsNum,
                trueAnswerNum:tempTrueNum,
                falseAnswerNum:tempFalseNum,
            }
        case 'R5_ACTIVEQUESTION':
            return{...state,activeQuestion:action.number}
        case 'R5_NEXTQUES':
            if(state.activeQuestion+1 <= state.questionList.length){
                return{...state,activeQuestion:state.activeQuestion+1}
            }
            else {
                return state
            }
        case 'R5_PREVQUES':
            if(state.activeQuestion-1 >= 1){
                return{...state,activeQuestion:state.activeQuestion-1}
            }
            else {
                return state
            }
        case 'R5_SETANSWER':
            let tempState=state;
            if(tempState.questionList[action.number].javab===action.answer)
            {
                tempState.questionList[action.number].javab=0;
            }
            else{
                tempState.questionList[action.number].javab=action.answer
            }
            return tempState;
        case 'R5_SETCOLOR':
            let tempState2=state;
            if(tempState2.questionList[action.number].colorStatus===action.color){
                tempState2.questionList[action.number].colorStatus=0;
                return tempState2;
            }
            else{
                tempState2.questionList[action.number].colorStatus=action.color;
                return tempState2;
            }
        case 'R5_SHOWRESULT':
            return {...state,showResult:!state.showResult}
        case 'R5_SETRESULTFROMSERVER':
            return {...state,serverAnswerList:action.result}
        default:
            return state;
            
    }

}

