import React, { useState } from 'react'
//redux
import {useDispatch, useSelector} from 'react-redux'
//import Styles
import style from './selectedItem.module.css'
//component

//font AwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTrashAlt,faEdit} from "@fortawesome/free-solid-svg-icons"
import { R11delEndList } from '../../redux/action'




//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function SelectedItem(props) {

    let temp="("+props.details.dars.name+")"+" "+"=>"+"تعداد سوال: "+" ("+props.details.number+") "+"مباحث"+"=>"+" "
    props.details.mabahes.forEach(item=>temp+=(" "+item.titlename+""+"/"+""))

    const dispatch=useDispatch()
    const deleteFunc=()=>{
        dispatch(R11delEndList(props.details))
    }
//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.selectedCourse}>
            <div className={style.number}>{props.number}</div>
            {/* <div className={style.editeBtn}>
                <FontAwesomeIcon  icon={faEdit} size="1x" />
            </div> */}
            <div className={style.delBtn} onClick={deleteFunc}>
                <FontAwesomeIcon  icon={faTrashAlt} size="1x" /> 
            </div>
            <div className={style.detailsCon}>{temp}</div>
        </div>

    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================