import React,{useEffect} from 'react';
import { BrowserRouter , Route , Switch } from 'react-router-dom';
import axios from 'axios'
//redux
import {useDispatch} from 'react-redux'
import {R2alert,R3loading,R4pages,R7allData} from './redux/action';
//component--------------------
import MainPage from './routes/MainPage'
import NotFound from './NotFound/NotFound';




// component Start ------------
function App(props) {
    const userStatus=localStorage.getItem('userStatus');
    const userTell=localStorage.getItem('userTell');
    const dispatch=useDispatch()
    useEffect(()=>{
        dispatch(R3loading(true))
        if(userStatus==='true'){
            axios({
                method:'post',
                url:'https://pejvak-konkur.ir/studentrest/infostudent',
                data:{
                    tel:userTell,
                    nawstr:"naw%a_m"
                }
            })
            .then(function (response) {
                console.log(response.data)
                        if(response.data===101){
                            dispatch(R3loading(false))
                            dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
                            
                        }else{
                            dispatch(R7allData(1,response.data,userTell))
                            if(response.data.Info!==undefined){
                                dispatch(R4pages(0))
                                dispatch(R3loading(false))
                            }
                            else{
                                getDataAndGoToPage()
                            }
                            
                        }
                        
                  })
            .catch((error)=>{
                dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
                dispatch(R3loading(false))
                dispatch(R4pages(-1))
            })
        }else{
            dispatch(R3loading(false))
            dispatch(R4pages(-1))
        }
    })


//=====================================================
// get data and go to Page--------------------
//=====================================================


const getDataAndGoToPage=()=>{

    axios({
        method:'post',
        url:'https://pejvak-konkur.ir/studentrest/infostudent',
        data:{
            tel:userTell,
            nawstr:"naw%a_m"
        }
    })
    .then(function (response) {
                if(response.data===101){
                    dispatch(R3loading(false))
                    dispatch(R2alert(true,"222 سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
                    
                }else{
                    dispatch(R7allData(1,response.data,userTell))
                    if(response.data.Info!==undefined){
                        dispatch(R4pages(0))
                        dispatch(R3loading(false))
                    }
                    else{
                        getDataAndGoToPage()
                    }
                    
                }
                
          })
    .catch((error)=>{
        dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
        
    })

}





return (
    
    <BrowserRouter>
        <div className="App">
            <Switch>
                <Route path="/" exact component={MainPage}/>
                <Route path="" component={NotFound}/>
                <Route path="/404" component={NotFound}/>  
            </Switch> 
        </div>
    </BrowserRouter>
  );
}

export default App;
