import React,{useEffect, useRef, useState} from 'react'
//font AwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowRight,faEye,faEyeSlash} from "@fortawesome/free-solid-svg-icons"
//import Styles
import style from './form6.module.css'
//validateFunction
import {checkRePassWithPass} from './../../function/validatePhoneNumber'
//redux
import {useDispatch,useSelector} from 'react-redux'
import {R1formActive,R2alert,R3loading} from './../../redux/action';



//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function Form6() {

//=====================================================
//show & hide Password input---------------------------
//=====================================================
const PassInputRef=useRef(null)
const [passInputStatus,setPassInputStatus]=useState(false)
//show hide func
const showPassFunc=()=>{
    if(!passInputStatus){
        PassInputRef.current.type="text";
        setPassInputStatus(true)
    }
    else{
        PassInputRef.current.type="password";
        setPassInputStatus(false) 
    } 
}
//autoFocuse func
useEffect(()=>{
    PassInputRef.current.focus()
},[])
//=====================================================
//show & hide Password input2--------------------------
//=====================================================
const PassInput2Ref=useRef(null)
const [passInput2Status,setPassInput2Status]=useState(false)
//show hide func
const showPassFunc2=()=>{
    if(!passInput2Status){
        PassInput2Ref.current.type="text";
        setPassInput2Status(true)
    }
    else{
        PassInput2Ref.current.type="password";
        setPassInput2Status(false) 
    } 
}
//=====================================================
//check And Down Reset Password--------------------------
//=====================================================
const dispatch=useDispatch()
const phonenumber=useSelector(state=>state.loginForm.phoneNumber)
const checkAndDownResetPassword=async()=>{
    console.log("click")
    dispatch(R3loading(true))
    if(await checkRePassWithPass(PassInputRef.current.value,PassInput2Ref.current.value)===0){
            // axios({
            //     method:'post',
            //     url:'https://www.onifood.ir/modir/shop/singup',
            //     data:{
            //         tell:phonenumber
            //     }
            // })
            // .then(function (response) {
            //         setSmsdata(response.data.code)
            //         setUserStatus(response.data.status)
            //         setWatingForPay(true);
            //       })
            // .catch((error)=>{
            //     dispatch(R3loading(false))
            //     dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
            // })
            setTimeout(() => {
                dispatch(R3loading(false))
                dispatch(R2alert(true,"okkkkkkkk"))
                dispatch(R1formActive(5,phonenumber))
            }, 1000);
    }    
    else if(await checkRePassWithPass(PassInputRef.current.value,PassInput2Ref.current.value)===1){
        dispatch(R3loading(false))
        dispatch(R2alert(true,"عدم تطابق تکرار رمز عبور"))
    }
    else{
        dispatch(R3loading(false))
        dispatch(R2alert(true,"رمز عبور شما باید حداقل ۶ حرفی و شامل یک رقم و یک کارکتر خاص(%!&,...)باشد."))
    }
}



//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={`${style.formContainer} animate__animated animate__fadeInRight`}>
            <h1>پژواک</h1>
            <h2>تغییر رمز عبور</h2>
            <h3>رمز عبور باید حداقل ۶ حرفی باشد</h3>
            <form>
                <input ref={PassInputRef} type="password" placeholder=' رمز عبور جدید' />
                <FontAwesomeIcon  icon={!passInputStatus?faEye:faEyeSlash} className={style.showPass} onClick={showPassFunc}/>
            </form>
            <form>
                <input ref={PassInput2Ref} type="password" placeholder='تکرار رمز عبور جدید' />
                <FontAwesomeIcon  icon={!passInput2Status?faEye:faEyeSlash} className={style.showPass} onClick={showPassFunc2}/>
            </form>
            <button onClick={checkAndDownResetPassword}>تایید</button>
            <FontAwesomeIcon  icon={faArrowRight} className={style.back}/>
        </div>
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================