import React from 'react';
import style from './box6.module.css'
//redux
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {R5activeQuestion } from '../../redux/action'

//function
import {toPersianNum} from './../../function/toPersian'
//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================

//=====================================================
export default function Box6() {
    //=====================================================
    // get data---------------------------------
    //=====================================================
    const holeAzmoun=useSelector(state=>state.azmoun)
    const dispatch=useDispatch();
    //=====================================================
    // render miniQuestion---------------------------------
    //=====================================================
    const renderMiniQuestionFunc=()=>{
        return holeAzmoun.questionList.map(item=>{
            let tempBackgroundColor="#cfd8dc"
            if(item.colorStatus===0){
                tempBackgroundColor="#cfd8dc"
            }
            else if(item.colorStatus===1){
                tempBackgroundColor="#ffeb3b"
            }
            else if(item.colorStatus===2){
                tempBackgroundColor="#e53935"
            }
            else{
                tempBackgroundColor="#8bc34a"
            }
            return  <div className={item.number===holeAzmoun.activeQuestion?style.sumeryAnswerActive:style.sumeryAnswer} key={item.number} onClick={()=>activeQuestionFunc(item)} style={{backgroundColor:`${tempBackgroundColor}`}}>
                        <div className={style.Qnumber}>{toPersianNum(item.number)}</div>
                        <div className={item.javab===1?style.Qa1An:style.Qa1}>۱</div>
                        <div className={item.javab===2?style.Qa1An:style.Qa1}>۲</div>
                        <div className={item.javab===3?style.Qa1An:style.Qa1}>۳</div>
                        <div className={item.javab===4?style.Qa1An:style.Qa1}>۴</div>
                    </div>
                       
        })
    }
    //=====================================================
    // activeQuestion---------------------------------
    //=====================================================
    const activeQuestionFunc=(item)=>{
        dispatch(R5activeQuestion(item.number))
    }
    //=====================================================
    // render of component---------------------------------
    //=====================================================
        return(
        <div className={style.detailsItem2} >
            {renderMiniQuestionFunc()}
        </div>
            
        )
    }
    //=====================================================
    //=====================================================
    //=====================================================
    //component end -------------------------------------
    //=====================================================
    //=====================================================
    //=====================================================