import React from 'react';
import { useEffect, useRef, useState } from 'react';
//style
import style from './dailyText.module.css';


//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function DailyText() {


    //=====================================================
    // render of component---------------------------------
    //=====================================================
        return(
            <div className={style.dailyTextContainer} >
                زندگی را باید ساده گرفت و تلاش کرد
            </div>
        )
    }
    //=====================================================
    //=====================================================
    //=====================================================
    //component end -------------------------------------
    //=====================================================
    //=====================================================
    //=====================================================