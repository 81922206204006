export const toPersianNum = (num, dontTrim) => {

    var i = 0,

        dontTrim = dontTrim || false,

        num = dontTrim ? num.toString() : num.toString().trim(),
        len = num.length,

        res = '',
        pos,

        persianNumbers = typeof persianNumber == 'undefined' ?
            ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'] :
            persianNumbers;

    for (; i < len; i++)
        if (( pos = persianNumbers[num.charAt(i)] ))
            res += pos;
        else
            res += num.charAt(i);

    return res;
}

export const toEnglishNum=( num, dontTrim ) =>{
    var i = 0,
        j = 0,
        dontTrim = dontTrim || false,
        num = dontTrim ? num.toString() : num.toString().trim(),
        len = num.length,
        res = '',
        pos,
        persianNumbers = typeof persianNumber == 'undefined' ?
            [ '۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹' ] :
            persianNumbers;

    for ( ; i < len; i++ )
        if ( ~( pos = persianNumbers.indexOf( num.charAt( i ) ) ) )
            res += pos;
        else
            res += num.charAt( i );
    return res;
};


export const presianDay=(day)=>{
    switch (day) {
        case 5:
            return 'جمعه'
        case 6:
            return 'شنبه'
        case 0:
            return 'یک شنبه'
        case 1:
            return 'دو شنبه'
        case 2:
            return 'سه شنبه'
        case 3:
            return 'چهارشنبه'
        case 4:
            return 'پنج شنبه'
        default:
            return ''
        }
    }