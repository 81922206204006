export const detailsEditForm = (state={status:false}, action) => {
    switch (action.type) {
        case 'R6_ACTIVE':
            return {status:true};
        case 'R6_DEACTIVE':
            return {status:false}
        default:
            return state;
            
    }
}