import React from 'react';
import { useSelector } from 'react-redux';
import style from './box2.module.css'
//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================

//=====================================================
export default function Box2() {
    const userDetails=useSelector(state=>state.userDetails)
    const showPayehFunc=()=>{
        if(userDetails.allData.Info.grade===12 ){
            return "دوازدهم"
        }else if(userDetails.allData.Info.grade===11){
            return "یازدهم"
        }else{
            return "دهم"
        }
            
    }
    //=====================================================
    // render of component---------------------------------
    //=====================================================
        return(
            <div className={style.detailsItem2}>
                <div className={style.studentContain}>
                    <div className={style.content}>{userDetails.allData.Info.name_f+" "+userDetails.allData.Info.name_l}</div>
                </div>
                <div className={style.schoollevelContain}>
                    <div className={style.label}>پایه تحصیلی :</div>
                    <div className={style.content}>{showPayehFunc()}</div>
                </div>
            </div>
    
        )
    }
    //=====================================================
    //=====================================================
    //=====================================================
    //component end -------------------------------------
    //=====================================================
    //=====================================================
    //=====================================================