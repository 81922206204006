import React from 'react'
//redux
import {useDispatch, useSelector} from 'react-redux'
import { R4pages } from '../redux/action';
//import Styles
import style from './page1.module.css'
//component
import CurentTime from '../5component-CurentTime/CurentTime';
import UserDetails from '../6component-userDetails/UserDetails';
import DailyText from '../7component-dailyText/DailyText';
import PageKey from '../8component-pageKey/PageKey';
//import Images
import img1 from './../img/page1BG1.png'
import img2 from './../img/page1BG2.png'
import img3 from './../img/page1BG3.png'







//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function Page1() {
//=====================================================
// go to  my Pejvok---------------------------------
//=====================================================
const dispatch=useDispatch()
const goToMyPejvokFunc=()=>{
    dispatch(R4pages(2,""))
}
//=====================================================
// go to  azmoun bank--------------------------------
//=====================================================

const goToAzmounBankFunc=()=>{
    dispatch(R4pages(1,""))
}


//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.Container1} style={{ backgroundImage: `url(${img1})` }}>
            <div className={style.ContainerOver}>
                <div className={style.Container2} >
                    <div className={style.headerContain}>
                        <div className={style.right}>
                            <CurentTime/>
                            <UserDetails/>
                        </div>
                        <div className={style.left}>
                            <DailyText/>
                            <PageKey/>
                        </div>
                    </div>
                    <div className={style.contentContain}>
                        <div className={`${style.Page1Item} animate__animated animate__lightSpeedInRight`} style={{ backgroundImage: `url(${img3})` }} onClick={goToMyPejvokFunc}>
                            پژواک من
                        </div>
                        <div className={`${style.Page1Item} animate__animated animate__lightSpeedInRight`} style={{ backgroundImage: `url(${img2})` }} onClick={goToAzmounBankFunc}>
                            بانک آزمون
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================