export const tempPishazmoun = (state={dars:undefined,mabahes:[],number:0,listEnd:[]}, action) => {
    switch (action.type) {
        case 'R11_SETTEMPPISHAZMOUNDARS':
            return {...state,dars:action.dars}
        case 'R11_RESETTEMOP':
            return {dars:undefined,mabahes:[],number:0,listEnd:state.listEnd}
        case 'R11_SETTEMPPISHAZMOUNMABHAS':
            let temp1=state.mabahes;
            temp1.push(action.mabhas)
            return {...state,mabahes:temp1}
        case 'R11_SETTEMPPISHAZMOUNMNUMBER':
            return {...state,number:action.number}
        case 'R11_DELTEMPPISHAZMOUNMABHAS':
            let temp3=[]
            state.mabahes.forEach(element => {
                if(element.titleid!==action.mabhas.titleid){
                    temp3.push(element)
                }
            });
            return {...state,mabahes:temp3}
        case 'R11_SETENDLIST':
            
            let temp2=state.listEnd;
            temp2.push(action.listEnd)
            return {...state,listEnd:temp2}
        case 'R11_DELENDLIST':
            
            let temp5=[];
            state.listEnd.forEach(item=>{
                if(item!==action.item){
                    temp5.push(item)  
                }
            })
            return {...state,listEnd:temp5}
        default:
            return state;
            
    }
}