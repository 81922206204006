import React,{useRef, useState} from 'react'
//moment
import moment from "moment-jalaali";
//import redux moudle
import {useDispatch,useSelector } from 'react-redux';
import {R12activePrint} from '../../redux/action';
//import to Persian
import {toPersianNum} from '../../function/toPersian'
//import Styles
import style from './item4.module.css'

//font AwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUserAlt} from "@fortawesome/free-solid-svg-icons"
import Alert from '../../3component-alert/Alert'

import useDynamicRefs from 'use-dynamic-refs';

import { ComponentToPrint1 } from '../../19component-pdf2/PdfRender2';
// print
import ReactToPrint from 'react-to-print';
//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function Item4() {

//=====================================================
// get data---------------------------------
//=====================================================
const allData=useSelector(state=>state.userDetails)
//=====================================================
// print---------------------------------
//=====================================================
const dispatch=useDispatch()
const printFunc=(item)=>{
    dispatch(R12activePrint(true,item))
}
//=====================================================
// render azmounak ---------------------------------
//=====================================================
const [reren,setreren]=useState(false)

const componentRef = useRef();
const [azmounakRef, setAzmounakRef] =  useDynamicRefs();
const closeAzmounak=(item)=>{
    setreren(!reren)
    const id1 = azmounakRef(item.id);
    if(id1.current.style.display==="flex"){
        
        id1.current.style.display="none"
    }
    else{
        id1.current.style.display="flex" 
    }
}
const renderAzmounak=()=>{
    return allData.allData.Exam.map((item,index)=>{
        let date = moment(item.date_exam , 'YYYY-MM-DD HH:mm:ss').format('jYYYY/jM/jD HH:mm:ss');
        if(item.type_exam===1){
            return      <div key={item.id} className={style.azmounContainer} onClick={()=>closeAzmounak(item)}>
                            <div className={style.azmounLabel}>{toPersianNum(index+1) + "--" + date}</div>
                            <div className={style.otherDetails} style={{display:"none"}} ref={setAzmounakRef(item.id)}>
                                {/* <div className={style.itemCon}>
                                    <div className={style.lab}>درس :</div>
                                    <div className={style.content}>{allData.allData.Lesson.find(tt=>tt.id===azmoun.azmounAkDetails.darsId).name}</div>
                                </div>
                                <div className={style.itemCon}>
                                    <div className={style.lab}>مبحث :</div>
                                    <div className={style.content}>{allData.allData.Titeltest.find(item=>item.id===azmoun.azmounAkDetails.mabhasId).titlename}</div>
                                </div>
                                <div className={style.itemCon}>
                                    <div className={style.lab}>سطح آزمون :</div>
                                    <div className={style.content}>{azmoun.azmounAkDetails.deficulty.name}</div>
                                </div> */}
                                <div className={style.itemCon}>
                                    <div className={style.lab}>تعداد سوال :</div>
                                    <div className={style.content}>{toPersianNum(item.total_exam)}</div>
                                </div>
                                <div className={style.itemCon}>
                                    <div className={style.lab}>سوالات پاسخ داده نشده :</div>
                                    <div className={style.content}>{toPersianNum(item.number_unselect)}</div>
                                </div>
                                <div className={style.itemCon}>
                                    <div className={style.lab}>صحیح :</div>
                                    <div className={style.content}>{toPersianNum(item.number_true)}</div>
                                </div>
                                <div className={style.itemCon}>
                                    <div className={style.lab}>غلط :</div>
                                    <div className={style.content}>{toPersianNum(item.number_false)}</div>
                                </div>
                                <div className={style.itemCon}>
                                    <div className={style.lab}>درصد بدون احتساب نمره منفی :</div>
                                    <div className={style.content}>{toPersianNum(parseFloat(item.score).toFixed(2))}</div>
                                </div>
                                <div className={style.itemCon}>
                                    <div className={style.lab}>درصد با احتساب نمره منفی :</div>
                                    <div className={style.content}>{toPersianNum(parseFloat(item.score_negative).toFixed(2))}</div>
                                </div>
                                <div className={style.itemCon}>
                                    <button className={style.printBtn} onClick={()=>printFunc(item)}>پرینت</button>  
                                </div>
                            </div>
                        </div>
        }

    })
}
//=====================================================
// render pishazmoun ---------------------------------
//=====================================================
const [pishazmounRef, setPishazmounRef] =  useDynamicRefs();
const closePishazmoun=(item)=>{
    const id1 = pishazmounRef(item.id);
    if(id1.current.style.display==="flex"){
        
        id1.current.style.display="none"
    }
    else{
        id1.current.style.display="flex" 
    }
}
const renderPishazmoun=()=>{
    return allData.allData.Exam.map((item,index)=>{
        let date = moment(item.date_exam , 'YYYY-MM-DD HH:mm:ss').format('jYYYY/jM/jD HH:mm:ss');
        if(item.type_exam===2){
            return      <div key={item.id} className={style.azmounContainer2} onClick={()=>closePishazmoun(item)}>
                            <div className={style.azmounLabel}>{toPersianNum(index+1) + "--" + date}</div>
                            <div className={style.otherDetails} style={{display:"none"}} ref={setPishazmounRef(item.id)}>
                                {/* <div className={style.itemCon}>
                                    <div className={style.lab}>درس :</div>
                                    <div className={style.content}>{allData.allData.Lesson.find(tt=>tt.id===azmoun.azmounAkDetails.darsId).name}</div>
                                </div>
                                <div className={style.itemCon}>
                                    <div className={style.lab}>مبحث :</div>
                                    <div className={style.content}>{allData.allData.Titeltest.find(item=>item.id===azmoun.azmounAkDetails.mabhasId).titlename}</div>
                                </div>
                                <div className={style.itemCon}>
                                    <div className={style.lab}>سطح آزمون :</div>
                                    <div className={style.content}>{azmoun.azmounAkDetails.deficulty.name}</div>
                                </div> */}
                                <div className={style.itemCon}>
                                    <div className={style.lab}>تعداد سوال :</div>
                                    <div className={style.content}>{toPersianNum(item.total_exam)}</div>
                                </div>
                                <div className={style.itemCon}>
                                    <div className={style.lab}>سوالات پاسخ داده نشده :</div>
                                    <div className={style.content}>{toPersianNum(item.number_unselect)}</div>
                                </div>
                                <div className={style.itemCon}>
                                    <div className={style.lab}>صحیح :</div>
                                    <div className={style.content}>{toPersianNum(item.number_true)}</div>
                                </div>
                                <div className={style.itemCon}>
                                    <div className={style.lab}>غلط :</div>
                                    <div className={style.content}>{toPersianNum(item.number_false)}</div>
                                </div>
                                <div className={style.itemCon}>
                                    <div className={style.lab}>درصد بدون احتساب نمره منفی :</div>
                                    <div className={style.content}>{toPersianNum(parseFloat(item.score).toFixed(2))}</div>
                                </div>
                                <div className={style.itemCon}>
                                    <div className={style.lab}>درصد با احتساب نمره منفی :</div>
                                    <div className={style.content}>{toPersianNum(parseFloat(item.score_negative).toFixed(2))}</div>
                                </div>
                                <div className={style.itemCon}>
                                    <button className={style.printBtn} onClick={()=>printFunc(item)}>پرینت</button>
                                </div>
                            </div>
                        </div>
        }

    })
}
//=====================================================
// render testShabaneh ---------------------------------
//=====================================================
const [testshabRef, setTestshabRef] =  useDynamicRefs();
const closeTestshab=(item)=>{
    const id1 = testshabRef(item.id);
    if(id1.current.style.display==="flex"){
        
        id1.current.style.display="none"
    }
    else{
        id1.current.style.display="flex" 
    }
}
const renderTestshab=()=>{
    return allData.allData.Exam.map((item,index)=>{
        let date = moment(item.date_exam , 'YYYY-MM-DD HH:mm:ss').format('jYYYY/jM/jD HH:mm:ss');
        if(item.type_exam===3){
            return      <div key={item.id} className={style.azmounContainer3} onClick={()=>closeTestshab(item)}>
                            <div className={style.azmounLabel}>{toPersianNum(index+1) + "--" + date}</div>
                            <div className={style.otherDetails} style={{display:"none"}} ref={setTestshabRef(item.id)}>
                                {/* <div className={style.itemCon}>
                                    <div className={style.lab}>درس :</div>
                                    <div className={style.content}>{allData.allData.Lesson.find(tt=>tt.id===azmoun.azmounAkDetails.darsId).name}</div>
                                </div>
                                <div className={style.itemCon}>
                                    <div className={style.lab}>مبحث :</div>
                                    <div className={style.content}>{allData.allData.Titeltest.find(item=>item.id===azmoun.azmounAkDetails.mabhasId).titlename}</div>
                                </div>
                                <div className={style.itemCon}>
                                    <div className={style.lab}>سطح آزمون :</div>
                                    <div className={style.content}>{azmoun.azmounAkDetails.deficulty.name}</div>
                                </div> */}
                                <div className={style.itemCon}>
                                    <div className={style.lab}>تعداد سوال :</div>
                                    <div className={style.content}>{toPersianNum(item.total_exam)}</div>
                                </div>
                                <div className={style.itemCon}>
                                    <div className={style.lab}>سوالات پاسخ داده نشده :</div>
                                    <div className={style.content}>{toPersianNum(item.number_unselect)}</div>
                                </div>
                                <div className={style.itemCon}>
                                    <div className={style.lab}>صحیح :</div>
                                    <div className={style.content}>{toPersianNum(item.number_true)}</div>
                                </div>
                                <div className={style.itemCon}>
                                    <div className={style.lab}>غلط :</div>
                                    <div className={style.content}>{toPersianNum(item.number_false)}</div>
                                </div>
                                <div className={style.itemCon}>
                                    <div className={style.lab}>درصد بدون احتساب نمره منفی :</div>
                                    <div className={style.content}>{toPersianNum(parseFloat(item.score).toFixed(2))}</div>
                                </div>
                                <div className={style.itemCon}>
                                    <div className={style.lab}>درصد با احتساب نمره منفی :</div>
                                    <div className={style.content}>{toPersianNum(parseFloat(item.score_negative).toFixed(2))}</div>
                                </div>
                                <div className={style.itemCon}>

                                      <button className={style.printBtn} onClick={()=>printFunc(item)}>پرینت</button>
 
                                </div>
                            </div>
                        </div>
        }

    })
}


//=====================================================
// render of component---------------------------------
//=====================================================
    return(
 
            
            <div className={style.container}>
                <div className={style.columnCon}>
                    <div className={style.label}>آزمونک</div>
                    {renderAzmounak()}
                </div>
                <div className={style.columnCon}>
                    <div className={style.label}>پیش آزمون</div>
                    {renderPishazmoun()}
                </div>
                <div className={style.columnCon}>
                    <div className={style.label}>تست شبانه</div>
                    {renderTestshab()}
                </div>
            </div>



    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================