import React from 'react';
import { useSelector } from 'react-redux';
import style from './box3b.module.css'

import { toPersianNum } from '../../function/toPersian';
//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================

//=====================================================
export default function Box3b() {
    const azmoun=useSelector(state=>state.azmoun)

    const renderList=()=>{
        let tempMabhas
        return azmoun.pishAzmounDetails.darsList.map((item,index)=>{
            let tempMabhas=""
            item.mabahes.forEach(ttt=>{
                tempMabhas+= ttt.titlename+" "+"----"+" "
            })
            return  <div key={index} className={style.eachCourseCon}>
                        <div className={style.detailsazmunContain}>
                            <div className={style.label}>درس {toPersianNum(index + 1)} :</div>
                            <div className={style.content}>{item.dars.name}</div>
                        </div>
                        <div className={style.mabahesCon}>
                            <div className={style.label}>مباحث :</div>
                            <div className={style.content}>{tempMabhas}</div>
                        </div>
                    </div>
        })
    }
    //=====================================================
    // render of component---------------------------------
    //=====================================================
        return(
        <div className={style.box3bCon}>
            <div className={style.mainLabel}>پیش آزمون</div>
            {renderList()}
        </div>
    
        )
    }
    //=====================================================
    //=====================================================
    //=====================================================
    //component end -------------------------------------
    //=====================================================
    //=====================================================
    //=====================================================