//============================================
//loginForm
//============================================
export const R1formActive = (fromNumber,phoneNumber) => ({
    type: "R1_FORM_ACTIVE",
    fromNumber,
    phoneNumber
})
//============================================
//ALert
//============================================
export const R2alert = (status,text) => ({
    type: "R2_ALERT",
    status,
    text
})
//============================================
//Loading
//============================================
export const R3loading = (status) => ({
    type: "R3_LOADING",
    status
})
//============================================
//pages
//============================================
export const R4pages = (pageActive,pageDetails) => ({
    type: "R4_PAGEACTIVE_ACTIVE",
    pageActive,
    pageDetails
})
export const R4backPage = () => ({
    type: "R4_BACK_PAGE",
})

//============================================
//azmoun
//============================================
export const R5setAzmoun = (questionList) => ({
    type: "R5_SET_QUESTION",
    questionList
})
export const R5setKindAzmoun = (kind,pishD,azmounakD,nightD) => ({
    type: "R5_SET_AZMOUNKIND",
    kind,
    pishD,
    azmounakD,
    nightD
})
export const R5startAzmoun = (date,startTime) => ({
    type: "R5_SET_STARTAZMOUN",
    date,
    startTime
})
export const R5endAzmoun = (date) => ({
    type: "R5_SET_ENDAZMOUN",
    date,

})
export const R5activeQuestion = (number) => ({
    type: "R5_ACTIVEQUESTION",
    number
})
export const R5nextQuestion = () => ({
    type: "R5_NEXTQUES",
})
export const R5prevQuestion = () => ({
    type: "R5_PREVQUES",
})
export const R5setAnswer = (number,answer) => ({
    type: "R5_SETANSWER",
    number,
    answer
})
export const R5setColor = (number,color) => ({
    type: "R5_SETCOLOR",
    number,
    color
})
export const R5showResult =()=>({
    type:"R5_SHOWRESULT"
})
export const R5serverResult =(result)=>({
    type:"R5_SETRESULTFROMSERVER",
    result
})
//============================================
//edit profile form
//============================================
export const R6active = () => ({
    type: "R6_ACTIVE",
})
export const R6deActive = () => ({
    type: "R6_DEACTIVE",
})
//============================================
//get all Data
//============================================
export const R7allData = (kindUser,allData,phoneNumber) => ({
    type: "R7_CONFIG_DATA",
    kindUser,
    allData,
    phoneNumber
})
//============================================
//edit profile form
//============================================
export const R8activeTab = (tabNumber,tabDetails) => ({
    type: "R8_ACTIVETAB",
    tabNumber,
    tabDetails
})
//============================================
//end azmoun result
//============================================
export const R9endAzmounAlert = (status,text,result) => ({
    type: "R9_ENAZMOUNALERT",
    status,
    text,
    result
})
//============================================
//pdf
//============================================
export const R10openPishAzmounSelect = () => ({
    type: "R10_OPENPISHAZMOUNSELECT",

})
//============================================
//temp pishazmoun
//============================================
export const R11setTempPishazmounDars = (dars) => ({
    type: "R11_SETTEMPPISHAZMOUNDARS",
    dars,

})
export const R11resetTempPish = () => ({
    type: "R11_RESETTEMOP",

})

export const R11setTempPishazmounMabhas = (mabhas) => ({
    type: "R11_SETTEMPPISHAZMOUNMABHAS",
mabhas
})
export const R11deleteTempPishazmounMabhas=(mabhas)=>({
    type: "R11_DELTEMPPISHAZMOUNMABHAS",
    mabhas 
})
export const R11setTempPishazmounNumber = (number) => ({
    type: "R11_SETTEMPPISHAZMOUNMNUMBER",
    number
})
export const R11setEndList = (listEnd) => ({
    type: "R11_SETENDLIST",
    listEnd
})
export const R11delEndList = (item) => ({
    type: "R11_DELENDLIST",
    item
})
//============================================
//temp print
//============================================
export const R12activePrint = (status,data) => ({
    type: "R12_ACTIVEPRINT",
    status,
    data
})

//============================================
//reportErorr
//============================================
export const R13erorr = (active,quesId) => ({
    type: "R13_ERORR",
    active,
    quesId

})