import React,{useState,useRef,useEffect} from 'react'
//function
import {phoneNumberValidate} from './../../function/validatePhoneNumber'
//redux
import {useDispatch,useSelector} from 'react-redux'
import {R1formActive,R2alert,R3loading} from './../../redux/action';
//font AwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowRight} from "@fortawesome/free-solid-svg-icons"
//import Styles
import style from './form4.module.css'



//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function Form4() {




//=====================================================
// activePhoneNumberInput------------------------------
//=====================================================
const inputRef=useRef(null)
useEffect(()=>{
    inputRef.current.focus()
},[])
//=====================================================
// next form function---------------------------------
//=====================================================
const dispatch=useDispatch()
const nextForm=async()=>{
    dispatch(R3loading(true))
    if(await phoneNumberValidate(inputRef.current.value)){
        sendToServer(inputRef.current.value)
    }else{
        dispatch(R3loading(false))
        dispatch(R2alert(true,"زبان را لاتین کنید یا شماره تلفن را درست وارد کنید."))
        inputRef.current.value=""
    }
}

const sendToServer=(phonenumber)=>{
// axios({
//     method:'post',
//     url:'https://www.onifood.ir/modir/shop/singup',
//     data:{
//         tell:phonenumber
//     }
// })
// .then(function (response) {
//         setSmsdata(response.data.code)
//         setUserStatus(response.data.status)
//         setWatingForPay(true);
//       })
// .catch((error)=>{
//     dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
// })
setTimeout(() => {
    dispatch(R3loading(false))
    // dispatch(R2alert(true,"okkkkkkkk"))
    dispatch(R1formActive(4,phonenumber))
}, 1000);
}

//=====================================================
// back To Perv Form---------------------------------
//=====================================================
const phonenumber=useSelector(state=>state.loginForm.phoneNumber) 
const backToPervForm=()=>{
    dispatch(R1formActive(2,phonenumber))
}






    
//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={`${style.formContainer} animate__animated animate__fadeInRight`}>
            <h1>پژواک</h1>
            <h3>برای بازیابی رمز عبور</h3>
            <h3>لطفاً شماره موبایل خود را وارد کنید.</h3>
            <form>
                <input type="number" placeholder='شماره همراه' ref={inputRef}/>     
            </form>
            <button onClick={nextForm} >ارسال</button>
            <FontAwesomeIcon  icon={faArrowRight} className={style.back} onClick={backToPervForm}/>
        </div>
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================