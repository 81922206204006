import React from 'react';
import ReactLoading from 'react-loading';
//import Styles
import style from './loading.module.css'


// component Start
// ---------------
export default function Loading() {

    return(
        <div className={`${style.Container} animate__animated animate__fadeIn animate-delay-0.5s`} >
            <ReactLoading type={"bubbles"} color={"#ffffff"} height={150} width={90} />
        </div>
    )
}