import React,{useEffect, useRef, useState} from 'react'
//font AwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleLeft,faArrowRight,faEye,faEyeSlash} from "@fortawesome/free-solid-svg-icons"
//import Styles
import style from './form3.module.css'
//redu
import {useDispatch,useSelector} from 'react-redux'
import {R1formActive,R2alert,R3loading,R4pages,R7allData} from './../../redux/action';
//axios 
import axios from 'axios'




//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function Form3() {


//=====================================================
//show & hide Password input---------------------------
//=====================================================
const PassInputRef=useRef(null)
const [passInputStatus,setPassInputStatus]=useState(false)
//show hide func
const showPassFunc=()=>{
    if(!passInputStatus){
        PassInputRef.current.type="text";
        setPassInputStatus(true)
    }
    else{
        PassInputRef.current.type="password";
        setPassInputStatus(false) 
    } 
}
//autoFocuse func
useEffect(()=>{
    PassInputRef.current.focus()
},[])

//=====================================================
// check password GetData And Login--------------------
//=====================================================
const dispatch=useDispatch()
const phonenumber=useSelector(state=>state.loginForm.phoneNumber)
const checkPasswordGetDataAndLogin=async()=>{
    dispatch(R3loading(true))
    
        axios({
            method:'post',
            url:'https://pejvak-konkur.ir/studentrest/login',
            data:{
                tel:phonenumber,
                pass:PassInputRef.current.value,
                nawstr:"naw%a_m"
            }
        })
        .then(function (response) {
            if(response.data===100){
                
                getDataAndGoToPage()
                
            }else if(response.data===1001){
                dispatch(R3loading(false))
                dispatch(R2alert(true,"رمزعبور اشتباه است٬دوباره تلاش کنید."))
                PassInputRef.current.value="";
            }else{
                dispatch(R3loading(false))
                dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
            }
              })
        .catch((error)=>{
            dispatch(R3loading(false))
            dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
        })

}
//=====================================================
// get data and go to Page--------------------
//=====================================================


const getDataAndGoToPage=()=>{

    axios({
        method:'post',
        url:'https://pejvak-konkur.ir/studentrest/infostudent',
        data:{
            tel:phonenumber,
            nawstr:"naw%a_m"
        }
    })
    .then(function (response) {
                if(response.data===101){
                    dispatch(R3loading(false))
                    dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
                    
                }else{
                    dispatch(R7allData(1,response.data))
                    if(response.data.Info!==undefined){
                        dispatch(R4pages(0))
                        dispatch(R3loading(false))
                    }
                    else{
                        getDataAndGoToPage()
                    }
                    
                }
                
          })
    .catch((error)=>{
        dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
        
    })

}
//=====================================================
// back To Perv Form---------------------------------
//===================================================== 
const backToPervForm=()=>{
    dispatch(R1formActive(0,phonenumber))
}
//=====================================================
// go recover pass---------------------------------
//===================================================== 
const goForgetPass=()=>{
    dispatch(R1formActive(3,phonenumber))
}




//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={`${style.formContainer} animate__animated animate__fadeInRight`}>
            <h1>پژواک</h1>
            <h2>رمز عبور را وارد کنید</h2>
            <form>
                <input ref={PassInputRef} type="password" placeholder='رمز عبور' />
                <FontAwesomeIcon  icon={!passInputStatus?faEye:faEyeSlash} className={style.showPass} onClick={showPassFunc}/>
            </form>
            <div className={style.forgetPass}>
                <p onClick={goForgetPass}>فراموشی رمز عبور</p>
                <FontAwesomeIcon  icon={faAngleLeft} className={style.arrow}/>
            </div>
            <button onClick={checkPasswordGetDataAndLogin} >تایید</button>
            <FontAwesomeIcon  icon={faArrowRight} className={style.back} onClick={backToPervForm}/>
        </div>
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================