import React, { useRef } from 'react'
//import redux moudle
import {useDispatch,useSelector } from 'react-redux';
// import {R6deActive} from '../../redux/action';
//import Styles
import style from './resultAzmoun.module.css'

//font AwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faWindowClose} from "@fortawesome/free-solid-svg-icons"
// import SelectList from '../../function/selectList/SelectList'

//data Picker

import { toPersianNum } from '../function/toPersian';
import {  R13erorr, R5showResult } from '../redux/action';
//markup
import Markup from 'react-html-markup';
// print
import ReactToPrint from 'react-to-print';

import { ComponentToPrint } from '../18component-pdf/PdfRender';
//dynamic ref
import useDynamicRefs from 'use-dynamic-refs';
//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function ResultAzmoun() {

const inserttt=(main_string, ins_string, pos)=> {
    if(typeof(pos) == "undefined") {
     pos = 0;
   }
    if(typeof(ins_string) == "undefined") {
     ins_string = '';
   }
    return main_string.slice(0, pos) + ins_string + main_string.slice(pos);
     }

//=====================================================
// get data---------------------------------
//=====================================================
const azmoun=useSelector(state=>state.azmoun)
const allData=useSelector(state=>state.userDetails)
let ourSubstring = "src=";     
const renderQuestionEditFunc=(item)=>{
    if(item.test.includes("src=")){
        let indexTemp=item.test.indexOf(ourSubstring)
        let tempString=inserttt(item.test,'https://pejvak-konkur.ir',(indexTemp+5));
        return   <Markup htmlString={ tempString } />
    }else{
        return   <Markup htmlString={ item.test } />
    }
    
}
const renderAnswerEditFunc=(item)=>{
    if(typeof item.answer==='string'){
        if(item.answer.includes("src=")){
            let indexTemp=item.answer.indexOf(ourSubstring)
            let tempString=inserttt(item.answer,'https://pejvak-konkur.ir',(indexTemp+5));
            return   <Markup htmlString={ tempString } />
        }else{
            return   <Markup htmlString={ item.answer } />
        }
    }else{
        return item.answer
    }
}

const renderAnswer1EditFunc=(item)=>{
    if(typeof item.answer1==='string'){
        if(item.answer1.includes("src=")){
            let indexTemp=item.answer1.indexOf(ourSubstring)
            let tempString=inserttt(item.answer1,'https://pejvak-konkur.ir',(indexTemp+5));
            return   <Markup htmlString={ tempString } />
        }else{
            return   <Markup htmlString={ item.answer1 } />
        }
    }else{
        return item.answer1
    }

}
const renderAnswer2EditFunc=(item)=>{
    if(typeof item.answer2==='string'){
        if(item.answer2.includes("src=")){
            let indexTemp=item.answer2.indexOf(ourSubstring)
            let tempString=inserttt(item.answer2,'https://pejvak-konkur.ir',(indexTemp+5));
            return   <Markup htmlString={ tempString } />
        }else{
            return   <Markup htmlString={ item.answer2 } />
        }
    }else{
        return item.answer2
    }

}
const renderAnswer3EditFunc=(item)=>{
    if(typeof item.answer3==='string'){
        if(item.answer3.includes("src=")){
            let indexTemp=item.answer3.indexOf(ourSubstring)
            let tempString=inserttt(item.answer3,'https://pejvak-konkur.ir',(indexTemp+5));
            return   <Markup htmlString={ tempString } />
        }else{
            return   <Markup htmlString={ item.answer3 } />
        }
    }else{
        return item.answer3
    }

}
const renderServerAnswer=(item)=>{
    const foundAnswer=azmoun.serverAnswerList.find(ele=>ele.id===parseInt(item.id))
    if(typeof foundAnswer.descriptiveanswer==='string'){
        if(foundAnswer.descriptiveanswer.includes("src=")){
            let indexTemp=foundAnswer.descriptiveanswer.indexOf(ourSubstring)
            let tempString=inserttt(foundAnswer.descriptiveanswer,'https://pejvak-konkur.ir',(indexTemp+5));
            return   <Markup htmlString={ tempString } />
        }else{
            return   <Markup htmlString={ foundAnswer.descriptiveanswer } />
        }
    }else{
        return foundAnswer.descriptiveanswer
    }

}
//=====================================================
// render question Lab---------------------------------
//=====================================================
const renderMabhasName=(mabhasId)=>{
   let nameMabhas=allData.allData.Titeltest.find(rrr=>rrr.titleid==mabhasId)
   return nameMabhas.titlename
}
//=====================================================
// open report---------------------------------
//=====================================================
const openReportErorr=(id)=>{
    dispatch(R13erorr(true,id))
}
//=====================================================
// render question Lab---------------------------------
//=====================================================
const [quesLabRef, setQuesLabRef] =  useDynamicRefs();
const renderQuestionLab=()=>{
    if(azmoun.questionList.length>0){
        return     azmoun.questionList.map(item=>{
            let bgColor="#e5e5e5";
            if(item.javab===0){
                bgColor="#e5e5e5";
            }else{
                if(item.javab===item.time-49){
                    bgColor="#06d6a0";
                }else{
                    bgColor="#f94144";
                }
            }
            return   <div className={style.topContainer} key={item.id}>
                        <div className={style.QuesLabelShoeBtn} style={{backgroundColor:`${bgColor}`}} onClick={()=>openDetailsFuncQ(item)}>
                             سوال{toPersianNum(item.number)}:
                        </div>
                        <div key={item.number} className={style.questionLabelCon} ref={setQuesLabRef(item.id)} style={{display:"none"}}>
                            <div className={style.sendErrorOfQuestion} onClick={()=>openReportErorr(item.id)}>گزارش خطا</div>
                            <div className={style.questionMabhas}>
                                {renderMabhasName(item.part)}
                            </div>
                            <div className={style.parCon}>
                                <div className={style.content}>
                                    {renderQuestionEditFunc(item)}
                                </div>
                            </div>
                            <div className={style.parCon}>
                                <div className={style.label} style={{color:`${item.time-49===1?"#06d6a0":""}`}}>گزینه ۱ :</div>
                                <div className={style.content} style={{backgroundColor:`${item.javab===1?"#e5e5e5":""}`}}>
                                    {renderAnswerEditFunc(item)}
                                </div>
                            </div>
                            <div className={style.parCon}>
                                <div className={style.label} style={{color:`${item.time-49===2?"#06d6a0":""}`}}>گزینه ۲ :</div>
                                <div className={style.content} style={{backgroundColor:`${item.javab===2?"#e5e5e5":""}`}}>
                                    {renderAnswer1EditFunc(item)}
                                </div>
                            </div>
                            <div className={style.parCon}>
                                <div className={style.label} style={{color:`${item.time-49===3?"#06d6a0":""}`}}>گزینه ۳ :</div>
                                <div className={style.content} style={{backgroundColor:`${item.javab===3?"#e5e5e5":""}`}}>
                                    {renderAnswer2EditFunc(item)}
                                </div>
                            </div>
                            <div className={style.parCon}>
                                <div className={style.label} style={{color:`${item.time-49===4?"#06d6a0":""}`}}>گزینه ۴ :</div>
                                <div className={style.content} style={{backgroundColor:`${item.javab===4?"#e5e5e5":""}`}}>
                                    {renderAnswer3EditFunc(item)}
                                </div>
                            </div>
                            <div className={style.parCon}>
                                <div className={style.label}>پاسخ تشریحی :</div>
                                <div className={style.content}>
                                    {renderServerAnswer(item)}
                                </div>
                            </div>
                            <div className={style.questionMabhas}>
                                {item.source}
                            </div>
                        </div>
                     </div>
            

        })
    }
}
const openDetailsFuncQ=(item)=>{
    const id1 = quesLabRef(item.id);
    if(id1.current.style.display==="flex"){
        
        id1.current.style.display="none"
    }
    else{
        id1.current.style.display="flex" 
    }
}
//=====================================================
// render question Lab---------------------------------
//=====================================================
const dispatch=useDispatch()
const closeFormFunc=()=>{

    dispatch(R5showResult())
}
//=====================================================
// render pdf---------------------------------
//=====================================================
const componentRef = useRef();
// const prindPdfFun=()=>{
//     print()

// }

//=====================================================
// render kind of Azmoun---------------------------------
//=====================================================
const renderPishAzmounMabhas=()=>{
    return azmoun.pishAzmounDetails.darsList.map((item,index)=>{
        let tempMabhas=""
        item.mabahes.forEach(ttt=>{
            tempMabhas+= ttt.titlename+" "+"----"+" "
        })
        return  <>
                    <div className={style.itemCon}>
                        <div className={style.lab}>درس {toPersianNum(index + 1)} :</div>
                        <div className={style.content}>{item.dars.name}</div>
                    </div>
                    <div className={style.itemCon}>
                        <div className={style.lab}>مباحث :</div>
                        <div className={style.content}>{tempMabhas}</div>
                    </div>
                </>

    })
}
const renderOnKindOfAzmoun=()=>{
    if(azmoun.azmounKind===1){
        
        return  <div className={style.easyDetails}>
                    <div className={style.itemCon}>
                        <div className={style.lab}>نوع آزمون :</div>
                        <div className={style.content}>آزمونک</div>
                    </div>
                    <div className={style.itemCon}>
                        <div className={style.lab}>درس :</div>
                        <div className={style.content}>{allData.allData.Lesson.find(item=>item.id===azmoun.azmounAkDetails.darsId).name}</div>
                    </div>
                    <div className={style.itemCon}>
                        <div className={style.lab}>مبحث :</div>
                        <div className={style.content}>{allData.allData.Titeltest.find(item=>item.id===azmoun.azmounAkDetails.mabhasId).titlename}</div>
                    </div>
                    <div className={style.itemCon}>
                        <div className={style.lab}>سطح آزمون :</div>
                        <div className={style.content}>{azmoun.azmounAkDetails.deficulty.name}</div>
                    </div>
                    <div className={style.itemCon}>
                        <div className={style.lab}>تعداد سوال :</div>
                        <div className={style.content}>{toPersianNum(azmoun.azmounAkDetails.questionNumber)}</div>
                    </div>
                    <div className={style.itemCon}>
                        <div className={style.lab}>سوالات پاسخ داده شده :</div>
                        <div className={style.content}>{toPersianNum(azmoun.answerNum)}</div>
                    </div>
                    <div className={style.itemCon}>
                        <div className={style.lab}>سوالات پاسخ داده نشده :</div>
                        <div className={style.content}>{toPersianNum(azmoun.noAnsverNum)}</div>
                    </div>
                    <div className={style.itemCon}>
                        <div className={style.lab}>صحیح :</div>
                        <div className={style.content}>{toPersianNum(azmoun.trueAnswerNum)}</div>
                    </div>
                    <div className={style.itemCon}>
                        <div className={style.lab}>غلط :</div>
                        <div className={style.content}>{toPersianNum(azmoun.falseAnswerNum)}</div>
                    </div>
                    <div className={style.itemCon}>
                        <div className={style.lab}>درصد بدون احتساب نمره منفی :</div>
                        <div className={style.content}>{toPersianNum((100/azmoun.azmounAkDetails.questionNumber)*(azmoun.trueAnswerNum))}</div>
                    </div>
                    <div className={style.itemCon}>
                        <div className={style.lab}>درصد با احتساب نمره منفی :</div>
                        <div className={style.content}>{toPersianNum((((azmoun.trueAnswerNum*3)-(azmoun.falseAnswerNum))/(azmoun.azmounAkDetails.questionNumber*3))*100)}</div>
                    </div>
                    <div className={style.itemCon}>
                        <ReactToPrint
                            trigger={() => <button className={style.printBtn}>پرینت</button>}
                            content={() => componentRef.current}
                        />
                        <div style={{display:"none"}}>
                            <ComponentToPrint ref={componentRef} />
                        </div>
                        
                    </div>
                </div>
    }else if(azmoun.azmounKind===2){
        return  <div className={style.easyDetails}>
                    <div className={style.itemCon}>
                        <div className={style.lab}>نوع آزمون :</div>
                        <div className={style.content}>پیش آزمون</div>
                    </div>
                    {renderPishAzmounMabhas()}
                    <div className={style.itemCon}>
                        <div className={style.lab}>تعداد سوال :</div>
                        <div className={style.content}>{toPersianNum(azmoun.pishAzmounDetails.questionNumber)}</div>
                    </div>
                    <div className={style.itemCon}>
                        <div className={style.lab}>سوالات پاسخ داده شده :</div>
                        <div className={style.content}>{toPersianNum(azmoun.answerNum)}</div>
                    </div>
                    <div className={style.itemCon}>
                        <div className={style.lab}>سوالات پاسخ داده نشده :</div>
                        <div className={style.content}>{toPersianNum(azmoun.noAnsverNum)}</div>
                    </div>
                    <div className={style.itemCon}>
                        <div className={style.lab}>صحیح :</div>
                        <div className={style.content}>{toPersianNum(azmoun.trueAnswerNum)}</div>
                    </div>
                    <div className={style.itemCon}>
                        <div className={style.lab}>غلط :</div>
                        <div className={style.content}>{toPersianNum(azmoun.falseAnswerNum)}</div>
                    </div>
                    <div className={style.itemCon}>
                        <div className={style.lab}>درصد بدون احتساب نمره منفی :</div>
                        <div className={style.content}>{toPersianNum((100/azmoun.pishAzmounDetails.questionNumber)*(azmoun.trueAnswerNum))}</div>
                    </div>
                    <div className={style.itemCon}>
                        <div className={style.lab}>درصد با احتساب نمره منفی :</div>
                        <div className={style.content}>{toPersianNum((((azmoun.trueAnswerNum*3)-(azmoun.falseAnswerNum))/(azmoun.pishAzmounDetails.questionNumber*3))*100)}</div>
                    </div>
                    <div className={style.itemCon}>
                        <ReactToPrint
                            trigger={() => <button className={style.printBtn}>پرینت</button>}
                            content={() => componentRef.current}
                        />
                        <div style={{display:"none"}}>
                            <ComponentToPrint ref={componentRef} />
                        </div>
                        
                    </div>
                </div>
    }
}



//=====================================================
// render of component---------------------------------
//=====================================================
    return(
            <div className={style.container}>
                <div className={style.container2}>
                    <FontAwesomeIcon className={style.closeBtn}  icon={faWindowClose} size="2x" onClick={closeFormFunc}/> 
                    {renderOnKindOfAzmoun()}
                    {renderQuestionLab()}
                   {/* <div className={style.questionCon}>
                       
                   </div> */}
                </div>
            </div>



    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================