import React, { useState } from 'react';
//redux
import {useDispatch,useSelector} from 'react-redux'
import {R2alert,R3loading,R13erorr} from '../redux/action';

//import Styles
import style from './reportErorr.module.css'
//axios
import axios from 'axios';

//font AwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faWindowClose} from "@fortawesome/free-solid-svg-icons"
//=====================================================
//=====================================================
//=====================================================
//component start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function Alert() {
const dispatch=useDispatch()
const quesId=useSelector(state=>state.reportErorr.quesId)


const [kindErorr,setKindErorr]=useState(0)

const setKindErorrFunc=(kind)=>{
    setKindErorr(kind)
}

const [comment,setComment]=useState("")
const getComment=(e)=>{
    e.preventDefault () 
    setComment(e.target.value)
}

const closeFormFunc=()=>{

    dispatch(R13erorr(false,undefined))
}
// const textOfAlert=useSelector(state=>state.alert.text)
//=====================================================
// ok alert function---------------------------------
//=====================================================

// const okAlert=()=>{
//     dispatch(R2alert(false,''))
//     dispatch(R3loading(false))
// }
//=====================================================
// sendReport---------------------------------
//=====================================================
const sendReport=()=>{
    dispatch(R3loading(true))
    console.log({
        nawstr:"naw%a_m",
        test_id:quesId,
        type:kindErorr,
        comment:comment,
    })
    axios({
        method:'post',
        url:'https://www.pejvak-konkur.ir/studentrest/reporttest',
        data:{
            nawstr:"naw%a_m",
            test_id:quesId,
            type:kindErorr,
            comment:comment,
        }
    })
    .then(function (response) {
            if(response.data===100){
                console.log(response.data)
                dispatch(R2alert(true,"گزارش خطا شما ثبت شد"))
                dispatch(R13erorr(false,undefined))
            }else if(response.data===101){
                dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
            }
    
          })
    .catch((error)=>{
        dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
        dispatch(R3loading(false))
    })
}
//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.Container} >
            
            <div className={`${style.reportErorrContainer} animate__animated animate__bounceInRight`}>
                <FontAwesomeIcon className={style.closeBtn}  icon={faWindowClose} size="2x" onClick={closeFormFunc}/>
                <div className={style.title} >نوع خطا را انتخاب کنید؟</div>
                <div className={style.kindError} style={kindErorr===1?{backgroundColor:"#333",color:"white"}:{}} onClick={()=>setKindErorrFunc(1)}>تکراری بودن سوال</div>
                <div className={style.kindError} style={kindErorr===2?{backgroundColor:"#333",color:"white"}:{}} onClick={()=>setKindErorrFunc(2)}>عدم مطابقت گزینه صحیح با پاسخ تشریحی</div>
                <div className={style.kindError} style={kindErorr===3?{backgroundColor:"#333",color:"white"}:{}} onClick={()=>setKindErorrFunc(3)}>غلط نگارشی٬املایی</div>
                <div className={style.kindError} style={kindErorr===4?{backgroundColor:"#333",color:"white"}:{}} onClick={()=>setKindErorrFunc(4)}>ایراد طراحی سوال</div>
                <div className={style.kindError} style={kindErorr===5?{backgroundColor:"#333",color:"white"}:{}} onClick={()=>setKindErorrFunc(5)}>سایر موارد</div>
                <textarea className={style.textArea} placeholder="توضیحات" onChange={getComment}></textarea>
                <div className={style.submitErorr} onClick={sendReport}>ثبت خطا</div>
            </div>
        </div>
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================