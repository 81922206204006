import React from 'react';
import { useEffect, useRef } from 'react';
//style
import style from './box1.module.css';



//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function Box1() {

    //=====================================================
    // get curent time---------------------------------
    //=====================================================
    const timeRef=useRef(null)
    const dayweekRef=useRef(null)
    const ddayRef=useRef(null)
    const mmonthRef=useRef(null)
    const yyearRef=useRef(null)
    useEffect(()=>{
        let timeInterVal=setInterval(() => {
            let d=new Date()
            timeRef.current.innerHTML=new Intl.DateTimeFormat('fa-IR-u-nu-latn', {timeStyle: 'medium'}).format(d)
            dayweekRef.current.innerHTML=new Intl.DateTimeFormat('fa-IR-u-nu-latn', {weekday: 'short'}).format(d)
            ddayRef.current.innerHTML=new Intl.DateTimeFormat('fa-IR-u-nu-latn', {day: 'numeric'}).format(d)
            mmonthRef.current.innerHTML=new Intl.DateTimeFormat('fa-IR-u-nu-latn', {month: 'short'}).format(d)
            yyearRef.current.innerHTML=new Intl.DateTimeFormat('fa-IR-u-nu-latn', {year: 'numeric'}).format(d)

        }, 1000);


        return(()=>clearInterval(timeInterVal))

    })

    //=====================================================
    // render of component---------------------------------
    //=====================================================
        return(
                        <div className={style.CurentTimeContainer}>
                            <div className={style.showTimeCon}>
                                <div className={style.dayOfWeek} ref={dayweekRef}></div>
                                <div className={style.timeNow} ref={timeRef}></div>
                            </div>
                            <div className={style.showDataCon}>
                                <div className={style.dday} ref={ddayRef}></div>
                                <div className={style.mmonth} ref={mmonthRef}></div>
                                <div className={style.yyears} ref={yyearRef}></div>
                            </div>
                        </div>
        )
    }
    //=====================================================
    //=====================================================
    //=====================================================
    //component end -------------------------------------
    //=====================================================
    //=====================================================
    //=====================================================