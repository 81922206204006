import React,{useEffect,useRef, useState} from 'react'
//font AwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleLeft,faArrowRight} from "@fortawesome/free-solid-svg-icons"
//import Styles
import style from './form5.module.css'
//redux
import {useDispatch,useSelector} from 'react-redux'
import {R1formActive,R2alert,R3loading} from './../../redux/action';
//function
import {smsCodeValidate} from './../../function/validatePhoneNumber'



//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function Form5() {





//=====================================================
// active smsCode Input------------------------------
//=====================================================
const inputRef=useRef(null)
useEffect(()=>{
    inputRef.current.focus()
},[])
//=====================================================
// timer down---------------------------------
//===================================================== 
const [resendState,setResendState]=useState(true)
const [timer,setTimer]=useState(120)
const showTimeRef=useRef(null)
const timerRef=useRef()
//made interval
useEffect(()=>{
    timerRef.current=setInterval(() => {
        if(timer>0){
            setTimer((prevTime)=>prevTime-1)
        }         
    }, 1000);
    return ()=>{
        clearInterval(timerRef.current)
    }
},[resendState])
if(timer===0){
    clearInterval(timerRef.current)
}
//render countdownNumber
const renderCuntDown=()=>{
    if(timer>60)
        return  <>
                    <p className={style.timer} ref={showTimeRef}>01:{timer-61}</p>
                    <p>مانده تا دریافت مجدد کد</p>
                </>      
    else if(timer>1){
        return <>
                    <p className={style.timer} ref={showTimeRef}>00:{timer-1}</p>
                    <p>مانده تا دریافت مجدد کد</p>
               </>     
    }
    else{
        return  <>
                    <p className={style.ResendCode} onClick={resendCode}>ارسال مجدد کد</p>
                </>
    }
}
const dispatch=useDispatch()
const phonenumber=useSelector(state=>state.loginForm.phoneNumber)
//resendCode
const resendCode=()=>{
    dispatch(R3loading(true))
    // axios({
//     method:'post',
//     url:'https://www.onifood.ir/modir/shop/singup',
//     data:{
//         tell:phonenumber
//     }
// })
// .then(function (response) {
//         setSmsdata(response.data.code)
//         setUserStatus(response.data.status)
//         setWatingForPay(true);
//       })
// .catch((error)=>{
//     dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
// })
setTimeout(() => {
    setTimer(120)
    setResendState(!resendState)
    dispatch(R3loading(false))
    // dispatch(R2alert(true,"okkkkkkkk"))
    dispatch(R1formActive(1,phonenumber))
}, 1000);
}
//=====================================================
// check Code GetData And Login------------------------
//===================================================== 
const checkCodeGetDataAndrePass=async()=>{
    dispatch(R3loading(true))
    if(await(smsCodeValidate(inputRef.current.value))){
        // axios({
        //     method:'post',
        //     url:'https://www.onifood.ir/modir/shop/singup',
        //     data:{
        //         tell:phonenumber
        //     }
        // })
        // .then(function (response) {
        //         setSmsdata(response.data.code)
        //         setUserStatus(response.data.status)
        //         setWatingForPay(true);
        //       })
        // .catch((error)=>{
        //     dispatch(R3loading(false))
        //     dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
        // })
        setTimeout(() => {
            dispatch(R3loading(false))
            // dispatch(R2alert(true,"okkkkkkkk"))
            dispatch(R1formActive(5,phonenumber))
        }, 1000);
    }
    else{
        dispatch(R3loading(false))
        dispatch(R2alert(true,"زبان را لاتین کنید یا کد ارسالی را درست وارد کنید."))
    }
}
//=====================================================
// back To Perv Form---------------------------------
//===================================================== 
const backToPervForm=()=>{
    dispatch(R1formActive(3,''))
}





//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={`${style.formContainer} animate__animated animate__fadeInRight`}>
            <h1>پژواک</h1>
            <h2>کد تایید را برای بازیابی پسورد وارد کنید</h2>
            <h3>کد تایید برای شماره {'09382236616'} پیامک شد.</h3>
            <form>
                <input ref={inputRef} type="number" placeholder='کد پیامک' autoComplete='one-time-code'/>     
            </form>
            <div className={style.timerContainer}>
                {renderCuntDown()}
            </div>
            <button onClick={checkCodeGetDataAndrePass}>بازیابی رمز عبور</button>
            <FontAwesomeIcon  icon={faArrowRight} className={style.back} onClick={backToPervForm}/>
        </div>
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================