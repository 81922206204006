import React,{useRef,useEffect} from 'react';
//function
import {phoneNumberValidate} from './../../function/validatePhoneNumber'
//redux
import {useDispatch} from 'react-redux'
import {R1formActive,R2alert,R3loading} from './../../redux/action';
//import Styles
import style from './form1.module.css'
//axios
import axios from 'axios';








//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function Form1() {

//=====================================================
// activePhoneNumberInput------------------------------
//=====================================================
const inputRef=useRef(null)
useEffect(()=>{
    inputRef.current.focus()
},[])

//=====================================================
// next form function---------------------------------
//=====================================================
const dispatch=useDispatch()
const nextForm=async()=>{
    dispatch(R3loading(true))
    if(await phoneNumberValidate(inputRef.current.value)){
        sendToServer(inputRef.current.value)
    }else{
        dispatch(R3loading(false))
        dispatch(R2alert(true,"زبان را لاتین کنید یا شماره تلفن را درست وارد کنید."))
        inputRef.current.value=""
    }
}

const sendToServer=(phonenumber)=>{
axios({
    method:'post',
    url:'https://www.pejvak-konkur.ir/studentrest/signup',
    data:{
        tel:phonenumber,
        nawstr:"naw%a_m"
    }
})
.then(function (response) {
        console.log(response.data)
        if(response.data===100){
            // dispatch(R3loading(false))
            getSmsTokenFunc(phonenumber)
            // dispatch(R1formActive(2,phonenumber))
        }else if(response.data===101){
            dispatch(R3loading(false))
            dispatch(R2alert(true,"نام کاربری نامعتبر می باشد٬ ابتدا ثبت نام کنید "))
        }

      })
.catch((error)=>{
    dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
    dispatch(R3loading(false))
})

}
//=====================================================
// get sms---------------------------------
//=====================================================
const getSmsTokenFunc=(telll)=>{
    axios({
        method:'post',
        url:'https://www.pejvak-konkur.ir/studentrest/sendtoken',
        data:{
            tel:telll,
            nawstr:"naw%a_m"
        }
    })
    .then(function (response) {
            if(response.data===100){
                console.log(response.data)
                dispatch(R3loading(false))
                // getSmsTokenFunc(phonenumber)
                dispatch(R1formActive(1,telll))
            }else if(response.data===101){
                dispatch(R3loading(false))
                dispatch(R2alert(true,"نام کاربری نامعتبر می باشد٬ ابتدا ثبت نام کنید "))
            }
    
          })
    .catch((error)=>{
        dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
        dispatch(R3loading(false))
    })
}
//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div  className={`${style.formContainer} animate__animated animate__fadeInRight`}>
            <h1>پژواک</h1>
            <h3>سلام</h3>
            <h3>لطفاً شماره موبایل خود را وارد کنید.</h3>
            <div className={style.form}>
                <input ref={inputRef} type="number" placeholder='شماره همراه' onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            nextForm()
                        }
                    }} />     
            </div>
            <button onClick={nextForm}>ورود</button>
            <div className={style.rulesContainer}>
                <p>با ورود به پژواک٬</p>
                <p className={style.rulesLink}>قوانین و مقررات پژواک</p>
                <p>را می پذیرم</p>
            </div>
        </div>
    )
}

//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================