

import React, { useEffect, useState } from 'react'
//redux
import {useDispatch,useSelector} from 'react-redux'
import {R5nextQuestion,R5prevQuestion,R2alert,R3loading,R5endAzmoun,R5showResult,R9endAzmounAlert} from './../redux/action';
//import Styles
import style from './azmoon.module.css'
//font AwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleRight,faAngleLeft} from "@fortawesome/free-solid-svg-icons"
//axios
import axios from 'axios';
//component
import Box2 from './box2-userDetails/Box2'
import Box1 from './box1-currentTime/Box1'
import Box3a from './box3a-azmoonakDetails/Box3a'
import Box3b from './box3b-pishAzmoon/Box3b'
import Box4 from './box4-startTime/Box4'
import Box5 from './box5-help/Box5'
import Box6 from './box6-miniQues/Box6'
import Box7 from './box7-questionMain/Box7'




//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function Azmoon() {
const azmoun=useSelector(state=>state.azmoun)
const allData=useSelector(state=>state.userDetails)
//=====================================================
// next and prev question-----------------------------
//=====================================================
const dispatch=useDispatch()
const nextQuestion=()=>{
    dispatch(R5nextQuestion())
}
const prevQuestion=()=>{
    dispatch(R5prevQuestion())
}
//=====================================================
// end azmoun function---------------------------------
//=====================================================

// const [oktosenddata,setOktosenddata]=useState(false)
const endAzmounFunc=()=>{
    dispatch(R9endAzmounAlert(true,'از پایان آزمون اطمینان دارید؟',false))
    dispatch(R3loading(true))
    dispatch(R5endAzmoun(new Date()))
    
    // setTimeout(() => {
    //     sendResultToServer() 
    // }, 3000);
    
}




//=====================================================
// send to server---------------------------------
//=====================================================
// const sendResultToServer=()=>{
//     if(azmoun.sendToServerList.length>0){
//         let templisttt=JSON.stringify(azmoun.sendToServerList)
//         console.log({
//             tel:allData.allData.Info.tel,
//             nawstr:"naw%a_m",
//             lesson_id:allData.allData.Lesson.find(item=>item.id===azmoun.azmounAkDetails.darsId).id,
//             part:allData.allData.Titeltest.find(item=>item.id===azmoun.azmounAkDetails.mabhasId).titleid,
//             list:templisttt,
//             total_exam:azmoun.azmounAkDetails.questionNumber,
//             time_start:azmoun.startDate,
//             time_end:azmoun.endDate,
//             number_false:azmoun.falseAnswerNum,
//             number_true:azmoun.trueAnswerNum,
//             number_unselect:azmoun.noAnsverNum,
//             score_negative:(((azmoun.trueAnswerNum*3)-(azmoun.falseAnswerNum))/(azmoun.azmounAkDetails.questionNumber*3))*100,
//             score:(100/azmoun.azmounAkDetails.questionNumber)*(azmoun.trueAnswerNum),
//         })

        
//         axios({
            
//             method:'post',
//             url:'https://pejvak-konkur.ir/studentrest/resultshorttest',
//             data:{
//                 tel:allData.allData.Info.tel,
//                 nawstr:"naw%a_m",
//                 lesson_id:allData.allData.Lesson.find(item=>item.id===azmoun.azmounAkDetails.darsId).id,
//                 part:allData.allData.Titeltest.find(item=>item.id===azmoun.azmounAkDetails.mabhasId).titleid,
//                 list:templisttt,
//                 total_exam:azmoun.azmounAkDetails.questionNumber,
//                 time_start:azmoun.startDate,
//                 time_end:azmoun.endDate,
//                 number_false:azmoun.falseAnswerNum,
//                 number_true:azmoun.trueAnswerNum,
//                 number_unselect:azmoun.noAnsverNum,
//                 score_negative:(((azmoun.trueAnswerNum*3)-(azmoun.falseAnswerNum))/(azmoun.azmounAkDetails.questionNumber*3))*100,
//                 score:(100/azmoun.azmounAkDetails.questionNumber)*(azmoun.trueAnswerNum),
//             }
//         })
//         .then(function (response) {
//             dispatch(R3loading(false))
//             console.log(response.data)

                    
//                 })
//         .catch((error)=>{
//             dispatch(R3loading(false))
//             dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
            
//         })
//     }else{
//         setOktosenddata(true)
//     }

// }
// useEffect(()=>{
//     if(oktosenddata){
//         sendResultToServer()
//         setOktosenddata(false)
//     }
// },[oktosenddata])
//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.azmoonCon}>
            <div className={style.part1Con}>
                <Box1/>
                <Box2/>
                {azmoun.azmounKind===1?<Box3a/>:""}
                {azmoun.azmounKind===2?<Box3b/>:""}
                <div className={style.endExamBtn} onClick={endAzmounFunc}>پایان آزمون</div>
                {/* <div className={style.discardExamBtn}>انصراف از آزمون</div> */}
            </div>
            <div className={style.part2Con}>
                <Box7/>
                <div className={style.nextQuestion} onClick={nextQuestion}>
                    <FontAwesomeIcon  icon={faAngleRight} size="2x" /> 
                </div>
                <div className={style.prevQuestion} onClick={prevQuestion}>
                    <FontAwesomeIcon  icon={faAngleLeft} size="2x"  />
                </div>
            </div>
            <div className={style.part3Con}>
                <Box4/>
                <Box5/>
                <Box6/>
            </div>
        </div>

    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================