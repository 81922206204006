import React,{useEffect,useRef, useState} from 'react'
//font AwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleLeft,faArrowRight} from "@fortawesome/free-solid-svg-icons"
//import Styles
import style from './form2.module.css'
//redux
import {useDispatch,useSelector} from 'react-redux'
import {R1formActive,R2alert,R3loading,R4pages,R7allData} from './../../redux/action';
//function
import {smsCodeValidate} from './../../function/validatePhoneNumber'
//axios
import axios from 'axios'
//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function Form2() {





//=====================================================
// active smsCode Input------------------------------
//=====================================================
const inputRef=useRef(null)
useEffect(()=>{
    inputRef.current.focus()
},[])
//=====================================================
// timer down---------------------------------
//===================================================== 
const [resendState,setResendState]=useState(true)
const [timer,setTimer]=useState(120)
const showTimeRef=useRef(null)
const timerRef=useRef()
//made interval
useEffect(()=>{
    timerRef.current=setInterval(() => {
        if(timer>0){
            setTimer((prevTime)=>prevTime-1)
        }         
    }, 1000);
    return ()=>{
        clearInterval(timerRef.current)
    }
},[resendState])
if(timer===0){
    clearInterval(timerRef.current)
}
//=====================================================
// resend code---------------------------------
//===================================================== 
const renderCuntDown=()=>{
    if(timer>60)
        return  <>
                    <p className={style.timer} ref={showTimeRef}>01:{timer-61}</p>
                    <p>مانده تا دریافت مجدد کد</p>
                </>      
    else if(timer>1){
        return <>
                    <p className={style.timer} ref={showTimeRef}>00:{timer-1}</p>
                    <p>مانده تا دریافت مجدد کد</p>
               </>     
    }
    else{
        return  <>
                    <p className={style.ResendCode} onClick={resendCode}>ارسال مجدد کد</p>
                </>
    }
}
const dispatch=useDispatch()
const phonenumber=useSelector(state=>state.loginForm.phoneNumber)
//resendCode
const resendCode=()=>{
    dispatch(R3loading(true))
    axios({
        method:'post',
        url:'https://www.pejvak-konkur.ir/studentrest/sendtoken',
        data:{
            tel:phonenumber,
            nawstr:"naw%a_m"
        }
    })
    .then(function (response) {
            if(response.data===100){
                dispatch(R3loading(false))
                // getSmsTokenFunc(phonenumber)
                dispatch(R1formActive(1,phonenumber))
            }else if(response.data===101){
                dispatch(R3loading(false))
                dispatch(R2alert(true,"نام کاربری نامعتبر می باشد٬ ابتدا ثبت نام کنید "))
            }
    
          })
    .catch((error)=>{
        dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
        dispatch(R3loading(false))
    })
setTimeout(() => {
    setTimer(120)
    setResendState(!resendState)
    dispatch(R3loading(false))
    // dispatch(R2alert(true,"okkkkkkkk"))
    dispatch(R1formActive(1,phonenumber))
}, 1000);
}
//=====================================================
// back To Perv Form---------------------------------
//===================================================== 
const backToPervForm=()=>{
    dispatch(R1formActive(0,''))
}

//=====================================================
// check Code GetData And Login------------------------
//===================================================== 
const checkCodeGetDataAndLogin=async()=>{
   
    if(timer>0){
        dispatch(R3loading(true))
        if(await(smsCodeValidate(inputRef.current.value))){
            console.log(phonenumber)
            console.log(inputRef.current.value)
            axios({
                method:'post',
                url:'https://www.pejvak-konkur.ir/studentrest/checktoken',
                data:{
                    tel:phonenumber,
                    nawstr:"naw%a_m",
                    checkcode:inputRef.current.value,
                }
            })
            .then(function (response) {
                console.log(response.data)
                if(response.data===100){
                    getDataAndGoToPage()
                }else if(response.data===101){
                    inputRef.current.value="";
                    dispatch(R3loading(false))
                    dispatch(R2alert(true,"کد وارد شده اشتباه می باشد "))
                }
                else if(response.data===1001){
                    dispatch(R3loading(false))
                    dispatch(R2alert(true,"خطای سیستمی دوباره تلاش کنید."))
                }
    
                  })
            .catch((error)=>{
                dispatch(R3loading(false))
                dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
            })
            // setTimeout(() => {
            //     dispatch(R3loading(false))
            //     // dispatch(R2alert(true,"okkkkkkkk"))
            //     dispatch(R1formActive(1,phonenumber))
            // }, 1000);
        }
        else{
            dispatch(R3loading(false))
            inputRef.current.value="";
            dispatch(R2alert(true,"زبان را لاتین کنید یا کد ارسالی را درست وارد کنید."))
        }
    }else{
        dispatch(R2alert(true,"زمان کد به پایان رسیده است"))
    }

}
//=====================================================
// get data and go to Page--------------------
//=====================================================


const getDataAndGoToPage=()=>{

    axios({
        method:'post',
        url:'https://pejvak-konkur.ir/studentrest/infostudent',
        data:{
            tel:phonenumber,
            nawstr:"naw%a_m"
        }
    })
    .then(function (response) {
                if(response.data===101){
                    dispatch(R3loading(false))
                    dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
                    
                }else{
                    dispatch(R7allData(1,response.data,phonenumber))
                    if(response.data.Info!==undefined){
                        dispatch(R4pages(0))
                        dispatch(R3loading(false))
                    }
                    else{
                        getDataAndGoToPage()
                    }
                    
                }
                
          })
    .catch((error)=>{
        dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
        
    })

}
//=====================================================
//login with password ---------------------------------
//===================================================== 
const loginWithPassword=()=>{
    dispatch(R1formActive(2,phonenumber))
}





//=====================================================
// render of component---------------------------------
//=====================================================    
    return(
        <div className={`${style.formContainer} animate__animated animate__fadeInRight`}>
            <h1>پژواک</h1>
            <h2>کد تایید را وارد کنید</h2>
            <h3>{`کد تایید برای شماره ${phonenumber} پیامک شد.`}</h3>
            <div className={style.form}>
                <input type="text" placeholder='کد پیامک' autoComplete='one-time-code' ref={inputRef} onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            checkCodeGetDataAndLogin()
                        }
                    }}/>     
            </div>
            {/* <div className={style.usePassword}>
                <p onClick={loginWithPassword}>ورود با رمز عبور</p>
                <FontAwesomeIcon  icon={faAngleLeft} className={style.arrow}/>
            </div> */}
            <div className={style.timerContainer}>
                {renderCuntDown()}
            </div>
            <button onClick={checkCodeGetDataAndLogin}    >تایید</button>
            <FontAwesomeIcon  icon={faArrowRight} className={style.back} onClick={backToPervForm}/>
        </div>
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================