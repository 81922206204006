import React,{ useEffect, useRef, useState } from 'react';
//import redux moudle
import {useDispatch,useSelector } from 'react-redux';
//to presian number
import { toPersianNum } from '../function/toPersian';
//moment
import moment from 'moment';
//style
import style from './userDetails.module.css';
//font AwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUserGraduate} from "@fortawesome/free-solid-svg-icons"


//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function UserDetails() {
    //=====================================================
    // get data---------------------------------
    //=====================================================
    const allData=useSelector(state=>state.userDetails)
    const showPayehFunc=()=>{
        if(allData.allData.Info.grade===12 ){
            return "دوازدهم"
        }else if(allData.allData.Info.grade===11){
            return "یازدهم"
        }else{
            return "دهم"
        }
            
    }
    //=====================================================
    // user pic---------------------------------
    //=====================================================
    let userPic;
    userPic='https://pejvak-konkur.ir/lib/student-img/'+allData.allData.Info.image+"."+allData.allData.Info.ext
    const returnUserPicFunc=()=>{
        if(allData.allData.Info.image!==null){
        return <img className={style.profilePic} src={userPic} alt="پروفایل"/>
        }else{
            return <FontAwesomeIcon  icon={faUserGraduate} className={style.iconn}/>
        }
    }
    //=====================================================
    // calculate days of account---------------------------------
    //=====================================================
    var start = moment().format("YYYY-MM-DD");
    // var start = moment(allData.allData.Info.start_date, "YYYY-MM-DD");
    var end = moment(allData.allData.Info.end_date, "YYYY-MM-DD");
    let daysOfAccount=moment.duration(end.diff(start)).asDays();


    //=====================================================
    // animate ---------------------------------
    //=====================================================
    const [animateStatus,setAnimateStatus]=useState(false)
    const addAnimate=()=>{
        setAnimateStatus(true)
    }
    const removeAnimate=()=>{
        setAnimateStatus(false)
    }
    //=====================================================
    // showPan --------------------------------------------
    //=====================================================
    const [showPan,setShowPan]=useState(false)
    const detailsPanRef=useRef()
    const openPan=()=>{
        if(!showPan){
            detailsPanRef.current.style.display="flex"
            setShowPan(true)
        }
        else{
            detailsPanRef.current.style.display="none"
            setShowPan(false)
        }
    }
    const detailsPanRef2=useRef()
    useEffect(() => {
        function handleClickOutside(event) {
          if (detailsPanRef2.current && !detailsPanRef2.current.contains(event.target)) {
                detailsPanRef.current.style.display="none"
                setShowPan(false)
          }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [showPan]);
    const holeExitFunc=()=>{
        localStorage.setItem('userTell',"")
        localStorage.setItem('userStatus',false)
        window.location.reload();
    }
    //=====================================================
    // render of component---------------------------------
    //=====================================================
        return(
            <div className={style.userDetailsContainer} ref={detailsPanRef2}>
                <div  className={`${style.userIconCon} ${animateStatus?"animate__animated animate__headShake":''} `} onMouseOver={addAnimate} onMouseOut={removeAnimate} onClick={openPan}>
                    {returnUserPicFunc()}
                </div>
                <div ref={detailsPanRef} className={`${style.DetailsPan} animate__animated animate__bounceInDown`}>
                    <div className={style.detailsItem}>
                        <div className={style.label}>نام:</div>
                        <div className={style.data}>{allData.allData.Info.name_f}</div>
                    </div>
                    <div className={style.detailsItem}>
                        <div className={style.label}>نام خانوادگی:</div>
                        <div className={style.data}>{allData.allData.Info.name_l}</div>
                    </div>
                    <div className={style.detailsItem}>
                        <div className={style.label}>رشته تحصیلی:</div>
                        <div className={style.data}>{allData.allData.Field.find(item=>item.id===allData.allData.Info.feild).name}</div>
                    </div>
                    <div className={style.detailsItem}>
                        <div className={style.label}>پایه تحصیلی:</div>
                        <div className={style.data}>{showPayehFunc()}</div>
                    </div>
                    <div className={style.detailsItem}>
                        <div className={style.label}>نام مشاور:</div>
                        <div className={style.data}>محمد نذری</div>
                    </div>
                    <div className={style.accountDetailsCon}>
                        <div className={style.accountItem}>
                            <div className={style.label}>نوع اکانت:</div>
                            <div className={style.data}>{allData.allData.Info.name}</div> 
                        </div>
                        <div className={style.accountItem}>
                            <div className={style.label}>آزمونک:</div>
                            <div className={style.data}>{allData.allData.Info.leftover_shorttest}</div> 
                        </div>
                        <div className={style.accountItem}>
                            <div className={style.label}>پیش آزمون:</div>
                            <div className={style.data}>{allData.allData.Info.number_pre_test}</div> 
                        </div>
                        <div className={style.accountItem}>
                            <div className={style.label}>مشاوره:</div>
                            <div className={style.data}>۳۰روز</div> 
                        </div>
                        <div className={style.accountItem}>
                            <div className={style.label}>آزمون ساز:</div>
                            <div className={style.data}>{toPersianNum(daysOfAccount)}روز</div> 
                        </div>
                        <div className={style.accountItem}>
                            <div className={style.label}>تست شبانه:</div>
                            <div className={style.data}>{allData.allData.Info.leftovertest}</div> 
                        </div>
                    </div>
                    <div className={style.exitBtn} onClick={holeExitFunc}>خروج</div>
                </div>
            </div>
        )
    }
    //=====================================================
    //=====================================================
    //=====================================================
    //component end -------------------------------------
    //=====================================================
    //=====================================================
    //=====================================================