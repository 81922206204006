import React ,{useRef,useEffect, useState}from 'react';
import style from './box4.module.css'
//redux
import {useDispatch, useSelector} from 'react-redux'
import {R2alert} from './../../redux/action';
//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================

//=====================================================
export default function Box4() {

    const dispatch=useDispatch()
    const azmoun=useSelector(state=>state.azmoun);

    let azmounDetailsTemp;
    if(azmoun.azmounKind===1){
        azmounDetailsTemp=azmoun.azmounAkDetails;
    }else{
        azmounDetailsTemp=azmoun.pishAzmounDetails; 
    }
    const [timerTemp,setTimerTemp]=useState(azmounDetailsTemp.timeD);
    let timeInterVal;
    useEffect(()=>{
        
        if(azmounDetailsTemp.timeStatus){
            timeInterVal=setInterval(() => {
                if(timerTemp>0){
                    setTimerTemp((prevTime)=>prevTime-1);
                }else{
                    goToEndTimeFunc()
                }
            }, 60000);
        }
        return(()=>clearInterval(timeInterVal))

    })
    const goToEndTimeFunc=()=>{
        dispatch(R2alert(true,"زمان تعیین شده توسط شما برای این آزمون پایان یافت٬شما می توانید آزمون را ادامه دهید"))
    }
    useEffect(()=>{
        if(azmoun.azmounstatus===false){
            clearInterval(timeInterVal)
        }
    })
    //=====================================================
    // render of component---------------------------------
    //=====================================================
        return(
            <div className={style.detailsItem2}>
                <div className={style.schoollevelContain}>
                    <div className={style.label}>زمان شروع آزمون:</div>
                    <div className={style.content}>{new Intl.DateTimeFormat('fa-IR-u-nu-latn', {timeStyle: 'medium'}).format(azmoun.startTime)}</div>
                </div>
                <div className={style.schoollevelContain}>
                    <div className={style.label}>زمان آزمون:</div>
                    <div className={style.content}>{azmounDetailsTemp.timeStatus?"دارد":"ندارد"}</div>
                </div>
                <div className={style.schoollevelContain}>
                    <div className={style.label}>زمان باقی مانده:</div>
                    <div className={style.content}>{timerTemp}</div>
                </div>
            </div>
    
        )
    }
    //=====================================================
    //=====================================================
    //=====================================================
    //component end -------------------------------------
    //=====================================================
    //=====================================================
    //=====================================================