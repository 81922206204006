import { combineReducers } from 'redux';
import { loginForm } from './REDUX-LoginForm';
import { alert } from './REDUX-alert';
import { loading } from './REDUX-loading';
import { pages } from './REDUX-pages';
import { azmoun } from './REDUX-azmoun';
import {detailsEditForm} from './REDUX-detailsEditForm'
import { userDetails } from './REDUX-userDetails';
import {myPejvokTab} from './REDUX-myPejvokTab';
import { endAzmounAlert } from './REDUX-endAzmounAlert';
import {openPishAzmounSelect} from './REDUX-openPishAzmounSelect'
import { tempPishazmoun } from './REDUX-tempPishazmoun';
import {printDetails} from './REDUX-printDetails'
import {reportErorr} from './REDUX-reportErorr'
export default combineReducers({
    loginForm,
    alert,
    loading,
    pages,
    azmoun,
    detailsEditForm,
    userDetails,
    myPejvokTab,
    endAzmounAlert,
    openPishAzmounSelect,
    tempPishazmoun,
    printDetails,
    reportErorr
})