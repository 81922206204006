import React from 'react';
import { useSelector } from 'react-redux';
import { toPersianNum } from '../function/toPersian';

import styles from './pdfRender.module.css'

//moment
import moment from "moment-jalaali";



export const ComponentToPrint1 = React.forwardRef((props, ref) => {
    const allData=useSelector(state=>state.userDetails)
    const renderKind=()=>{

        if(printDetails.type_exam===1){
            return <div className={styles.content}>آزمونک</div>
        }else if(printDetails.type_exam===2){
            return <div className={styles.content}>پیش آزمون</div>
        }else{
            return <div className={styles.content}>تست شبانه</div>
        }
    }

    const printDetails=useSelector(state=>state.printDetails.data)

    const renderOnkind=()=>{
            let date = moment(printDetails.date_exam , 'YYYY-MM-DD HH:mm:ss').format('jYYYY/jM/jD HH:mm:ss');
            return  <div ref={ref} style={{ width: "210mm", height: "297mm" ,direction:"rtl",paddingTop:"50px"}}>      
                        <div className={styles.section}>
                            <div className={styles.label}>نام دانش آموز :</div>
                            <div className={styles.content}>{allData.allData.Info.name_f +" "+allData.allData.Info.name_l}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}> نوع آزمون:</div>
                            {renderKind()}
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}>تاریخ :</div>
                            <div className={styles.content}>{date}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}> تعداد سوال:</div>
                            <div className={styles.content}>{toPersianNum(printDetails.total_exam)}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}> سوالات پاسخ داده نشده:</div>
                            <div className={styles.content}>{toPersianNum(printDetails.number_unselect)}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}>صحیح :</div>
                            <div className={styles.content}>{toPersianNum(printDetails.number_true)}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}>غلط :</div>
                            <div className={styles.content}>{toPersianNum(printDetails.number_false)}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}> درصد بدون احتساب نمره منفی:</div>
                            <div className={styles.content}>{toPersianNum(parseFloat(printDetails.score).toFixed(2))}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}> درصد با احتساب نمره منفی:</div>
                            <div className={styles.content}>{toPersianNum(parseFloat(printDetails.score_negative).toFixed(2))}</div>
                        </div>
                    </div>
  
    }

    return (
      
        renderOnkind()
    );
  });