import React, { useReducer } from 'react'

//import redux moudle
import {useDispatch, useSelector } from 'react-redux';
import {R6active} from '../../redux/action';

//import Styles
import style from './item1.module.css'

//font AwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUserAlt} from "@fortawesome/free-solid-svg-icons"
import Alert from '../../3component-alert/Alert'





//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function Item1() {
//=====================================================
// get data---------------------------------
//=====================================================
const allData=useSelector(state=>state.userDetails)
const showPayehFunc=()=>{
    if(allData.allData.Info.grade===12 ){
        return "دوازدهم"
    }else if(allData.allData.Info.grade===11){
        return "یازدهم"
    }else{
        return "دهم"
    }
        
}
//=====================================================
// user pic---------------------------------
//=====================================================
let userPic;
userPic='https://pejvak-konkur.ir/lib/student-img/'+allData.allData.Info.image+"."+allData.allData.Info.ext
const returnUserPicFunc=()=>{
    if(allData.allData.Info.image!==null){
       return <img className={style.profilePic} src={userPic} alt="پروفایل"/>
    }else{
        return  <div className={style.noPic}>
                    <FontAwesomeIcon  icon={faUserAlt} size="2x" /> 
                </div>
    }
}


//=====================================================
// openEditForm---------------------------------
//=====================================================
const dispatch=useDispatch()
const openEditFormFunc=()=>{
    dispatch(R6active())
}


//=====================================================
// render of component---------------------------------
//=====================================================
    return(
 
            
            <div className={style.container} >
                <div className={style.itemsContain22}>
                    {returnUserPicFunc()}
                    <div className={style.editProfileBtn} onClick={openEditFormFunc}>ویرایش اطلاعات</div>
                </div>
                <div className={style.itemsContain}>
                    <div className={style.label}>نام :</div>
                    <div className={style.content}>{allData.allData.Info.name_f}</div>
                </div>
                <div className={style.itemsContain}>
                    <div className={style.label}>نام خانوادگی :</div>
                    <div className={style.content}>{allData.allData.Info.name_l}</div>
                </div>
                <div className={style.itemsContain}>
                    <div className={style.label}>تاریخ تولد :</div>
                    <div className={style.content}>{allData.allData.Info.birthday===null?"--":allData.allData.Info.birthday}</div>
                </div>
                <div className={style.itemsContain}>
                    <div className={style.label}>آخرین معدل  :</div>
                    <div className={style.content}>{allData.allData.Info.average===null?"--":allData.allData.Info.average}</div>
                </div>
                <div className={style.itemsContain}>
                    <div className={style.label}>پایه تحصیلی :</div>
                    <div className={style.content}>{showPayehFunc()}</div>
                </div>
                <div className={style.itemsContain}>
                    <div className={style.label}>رشته تحصیلی :</div>
                    <div className={style.content}>{allData.allData.Field.find(item=>item.id===allData.allData.Info.feild).name}</div>
                </div>
                <div className={style.itemsContain}>
                    <div className={style.label}>شهرستان :</div>
                    <div className={style.content}>{allData.allData.Info.city}</div>
                </div>
                <div className={style.itemsContain}>
                    <div className={style.label}>ایمیل :</div>
                    <div className={style.content}>{allData.allData.Info.email===null?"--":allData.allData.Info.email}</div>
                </div>
                <div className={style.itemsContain}>
                    <div className={style.label}>شماره همراه :</div>
                    <div className={style.content}>{allData.allData.Info.tel}</div>
                </div>
            </div>



    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================