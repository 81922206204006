import React, { useRef, useState } from 'react'
//axios
import axios from 'axios';
//redux
import {useDispatch,useSelector } from 'react-redux';
import {R5setAzmoun,R5setKindAzmoun,R5startAzmoun,R2alert,R4pages,R3loading} from './../redux/action';
//import Styles
import style from './page3.module.css'
//component
import CurentTime from '../5component-CurentTime/CurentTime';
import UserDetails from '../6component-userDetails/UserDetails';
import DailyText from '../7component-dailyText/DailyText';
import PageKey from '../8component-pageKey/PageKey';
import SelectList from './selectList/SelectList';
//import Images
import img1 from './../img/page3BG1.png'
import img2 from './../img/page2BG2.png'
import img3 from './../img/page2BG3.png'





//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function Page3() {
//=====================================================
// get AllData---------------------------------
//=====================================================
const allData=useSelector(state=>state.userDetails)

//=====================================================
// taggole button---------------------------------
//=====================================================
const[taggoleStatus,setTaggoleStatus]=useState(false)
const taggoleFun=()=>{
    setTaggoleStatus(!taggoleStatus)
}
//=====================================================
// renderTimeInput---------------------------------
//=====================================================
const azmunTimeRef=useRef()
const renderTimeInput=()=>{
    if(taggoleStatus===true){
        return  <div className={style.selectContain}>
                    <label htmlFor="chdoshvari">زمان را وارد کنید :</label>
                    <div className={style.item2}>
                        <input type="number" name="" ref={azmunTimeRef} className={style.numberInput}  placeholder="زمان را به دقیق وارد کنید" onChange={getTimeOfAzmunFunc}/>
                    </div>
                </div>
    }

}
let timeInMinute=0;
const getTimeOfAzmunFunc=()=>{
    timeInMinute=azmunTimeRef.current.value;
}

//=====================================================
// get darse---------------------------------
//=====================================================
let darsList =[{id:-1,name:"انتخاب درس",active:true}];
allData.allData.Lesson.forEach(element => {
    darsList.push({...element,active:false})
});
const [darsSelected,setDarsSelected]=useState()        
const getSelectedItem2=(item)=>{
    setDarsSelected(item);
}
//=====================================================
// get mabhas---------------------------------
//=====================================================
let mabhasList =[{id:-1,name:"انتخاب مبحث",active:true}];


    if(darsSelected!==undefined){
        allData.allData.Titeltest.forEach(element => {
            if(element.lessonid===darsSelected.id){
                mabhasList.push({...element,name:element.titlename,active:false,id:element.titleid})
            }
            
        });
    }        

const [mabhasSelected,setMabhasSelected]=useState()  
const getSelectedItem3=(item)=>{
    setMabhasSelected(item);
}
//=====================================================
// get number of Question---------------------------------
//=====================================================
let questionNumberList =[{id:-1,name:"تعداد سوال",active:true},{id:2,name:"5",number:5,active:false},{id:3,name:"10",number:10,active:false},{id:4,name:"15",number:15,active:false},
,{id:5,name:"20",number:20,active:false},{id:6,name:"30",number:30,active:false},{id:7,name:"40",number:40,active:false},{id:8,name:"50",number:50,active:false}];
       

const [questionNumberSelect,setQuestionNumberSelect]=useState()  
const getSelectedItem4=(item)=>{
    setQuestionNumberSelect(item);
}
//=====================================================
// deficulty of test---------------------------------
//=====================================================
let deficultyList =[
{id:1,name:"متعادل",number:1,active:true},
{id:2,name:"آسان",number:2,active:false},
{id:3,name:"متوسط",number:3,active:false},
{id:4,name:"دشوار",number:4,active:false}
];
       

const [deficultySelect,setDeficultySelect]=useState({id:1,name:"متعادل",number:1,active:true}) 
const getSelectedItem5=(item)=>{
    setDeficultySelect(item);
}
//=====================================================
// create azmounak---------------------------------
//=====================================================
const dispatch=useDispatch()
const createAzmounakFunc=()=>{
    dispatch(R3loading(true))
    if(darsSelected!==undefined){
        if(darsSelected.id!==-1){
            if(mabhasSelected!==undefined){
                if(mabhasSelected.id!==-1){
                    if(questionNumberSelect!==undefined){
                        if(questionNumberSelect.id!==-1){
                            console.log(darsSelected.id)
                            console.log(mabhasSelected.titleid)
                            console.log(questionNumberSelect.number)
                            axios({
                                method:'post',
                                url:'https://pejvak-konkur.ir/studentrest/createshorttest',
                                data:{
                                    tel:allData.allData.Info.tel,
                                    lesson_id:darsSelected.id,
                                    nawstr:"naw%a_m",
                                    part:mabhasSelected.titleid,
                                    count:questionNumberSelect.number,
                        
                                }
                            })
                            .then(function (response) {
                                console.log(response.data)
                                if(response.data===101){
                                    dispatch(R3loading(false))
                                    dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
                                }else if(response.data===105){
                                    dispatch(R3loading(false))
                                    dispatch(R2alert(true,"محدودیت تعداد سوال برای این مبحث"))
                                }else if(response.data===106){
                                    dispatch(R3loading(false))
                                    dispatch(R2alert(true,"شما امروز تعداد آزمونک های مجاز خود را استفاده کرده اید"))
                                }else{
                                    console.log(response.data)
                                    dispatch(R3loading(false))
                                    dispatch(R5setKindAzmoun(1,{},{darsId:darsSelected.id,
                                                                    mabahsId:mabhasSelected.titleid,
                                                                    questionNumber:questionNumberSelect.number,
                                                                    deficulty:deficultySelect,
                                                                    timeStatus:taggoleStatus,
                                                                    timeD:timeInMinute
                                                                }));
                                    dispatch(R5startAzmoun(new Date()))
                                    dispatch(R5setAzmoun(response.data.test))
                                    dispatch(R4pages(5,""))
                                }
                        
                                })
                            .catch((error)=>{
                                dispatch(R3loading(false))
                                dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
                            })
                        }
                        else{
                            dispatch(R3loading(false))
                            dispatch(R2alert(true,"تعداد سوال را انتخاب کنید."))
                        }
                    }else{
                        dispatch(R3loading(false))
                        dispatch(R2alert(true,"تعداد سوال را انتخاب کنید."))
                    }
                }
                else{
                    dispatch(R3loading(false))
                    dispatch(R2alert(true,"مبحث را انتخاب کنید."))
                }
            }else{
                dispatch(R3loading(false))
                dispatch(R2alert(true,"مبحث را انتخاب کنید."))
            }
        }else{
            dispatch(R3loading(false))
            dispatch(R2alert(true,"درس را انتخاب کنید."))
        }
    }else{
        dispatch(R3loading(false))
        dispatch(R2alert(true,"درس را انتخاب کنید."))
    }


}
//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.Container1} style={{ backgroundImage: `url(${img1})` }}>
            <div className={style.ContainerOver}>
                <div className={style.Container2} >
                    <div className={style.headerContain}>
                        <div className={style.right}>
                            <CurentTime/>
                            <UserDetails/>
                        </div>
                        <div className={style.left}>
                            <DailyText/>
                            <PageKey/>
                        </div>
                    </div>
                    <div className={style.contentContain}>
                        <div className={style.contentRight} >
                            <div className={style.mainLabel}>ساخت آزمونک</div>
                            {/* <div className={style.selectContain}>
                                <label htmlFor="chPayeh">پایه تحصیلی :</label>
                                <div className={style.item2}>
                                    <SelectList List={payehList} sendSelectedItem={getSelectedItem1}/>
                                </div>
                            </div> */}
                            <div className={style.selectContain}>
                                <label htmlFor="chDars">درس :</label>
                                <div className={style.item2}>
                                    <SelectList List={darsList} sendSelectedItem={getSelectedItem2}/>
                                </div>
                            </div>
                            <div className={style.selectContain}>
                                <label htmlFor="chMabhas">مبحث :</label>
                                <div className={style.item2}>
                                    <SelectList List={mabhasList} sendSelectedItem={getSelectedItem3}/>
                                </div>
                            </div>
                            <div className={style.selectContain}>
                                <label htmlFor="chNumberQuestion">تعداد سوال :</label>
                                <div className={style.item2}>
                                    <SelectList List={questionNumberList} sendSelectedItem={getSelectedItem4}/>
                                </div>
                            </div>
                            <div className={style.selectContain}>
                                <label htmlFor="chdoshvari">سطح سوالات :</label>
                                <div className={style.item2}>
                                    <SelectList List={deficultyList} sendSelectedItem={getSelectedItem5}/>
                                </div>
                            </div>
                            <div className={style.selectContain}>
                                <label htmlFor="chdoshvari">زمان آزمون :</label>
                                <div className={style.taggoleContain}>
                                    <div className={`${style.buttonOfTaggole}  ${taggoleStatus?style.taggoleActive:style.taggoleDeActive}`} onClick={taggoleFun}></div>
                                </div>
                            </div>
                            {renderTimeInput()}
                            <button onClick={createAzmounakFunc}>ساخت آزمون</button>
                        </div>
                        <div className={`${style.contentLeft} animate__animated animate__lightSpeedInRight`} >
                            <h1>آزمونک</h1>
                            <p>
                            در این بخش می توانید به راحتی برای هر مبحث جزء از هر درس یا هر بخش از هر کتاب که مطالعه نموده،آزمونی با ویژگی های یک آزمون کامل ایجاد نموده تا علاوه بر تمرین تست زنی با انواع تست های آن مبحث مورد مطالعه آشنا و همچنین نوع و میزان تسلط خود برای آن مبحث را نیز مورد سنجش قرار دهید.                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================