import React from 'react';
import ReactDOM from 'react-dom';
import './../node_modules/vazir-font/dist/font-face.css';
import './PublicStyles/styles/resetStyles.css';
import 'animate.css';
import './PublicStyles/styles/localStyles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import {createStore} from 'redux';
import Reducer from './redux/reducer'
import { composeWithDevTools } from 'redux-devtools-extension';
const store = createStore( Reducer,composeWithDevTools());
ReactDOM.render(
    <Provider store={store}>
        <App></App>
    </Provider>,
  document.getElementById('root')
);

reportWebVitals();
