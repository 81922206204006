
// =====================================================
export const phoneNumberValidate=async(phoneNumber)=>{
    let result=true;
    let checkEnglishNumber=new RegExp("[\u0600-\u06FF]");
    let iranPhoneNumber=new RegExp("09(1[0-9]|3[1-9]|2[1-9])-?[0-9]{3}-?[0-9]{4}");

    if(checkEnglishNumber.test(phoneNumber) === true){
        result=false;
    }
    if(iranPhoneNumber.test(phoneNumber) === false){
        result=false;
    }
    return result;
}
// ======================================================

export const emailValidate=async(email)=>{
    let emailRegex=/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
    if(emailRegex.test(email))
        return true;
    else
        return false;

}
// ======================================================
export const smsCodeValidate=async(number)=>{
    let result=true;
    let checkEnglishNumber=new RegExp("[\u0600-\u06FF]");
    let smsCodeNumber=new RegExp(/[0-9]+$/);

    if(checkEnglishNumber.test(number)){
        result=false;
    }
    if(!smsCodeNumber.test(number)){
        result=false;
    }
    return result;
}
// ======================================================
export const checkRePassWithPass=async(pass,rePass)=>{
    let regularExpressionPass =new RegExp(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/) ;
    if(regularExpressionPass.test(pass)){
        if(pass===rePass){
            return 0;
        }
        else{
            return 1;
        }
    }
    else{
        return 2;
    }
}
//=======================================================
export const checkMoadelvali=async(moadel)=>{
    let regExpp=/^(\d{2})([/])(\d{2})|([۱۲۳۴۵۶۷۸۹]{2})([/])([۱۲۳۴۵۶۷۸۹]{2})$/
    if(regExpp.test(moadel))
        return true;
    else
        return false;

}
// var numberRegex = /^\s*[+-]?(\d+|\d*\.\d+|\d+\.\d*)([Ee][+-]?\d+)?\s*$/
// var isNumber = function(s) {
//     return numberRegex.test(s);
// };

// "0"           => true
// "3."          => true
// ".1"          => true
// " 0.1 "       => true
// " -90e3   "   => true
// "2e10"        => true
// " 6e-1"       => true
// "53.5e93"     => true

// "abc"         => false
// "1 a"         => false
// " 1e"         => false
// "e3"          => false
// " 99e2.5 "    => false
// " --6 "       => false
// "-+3"         => false
// "95a54e53"    => false



// DOCUMENT

// const test=new RegExp("hey")
// or
// const test=/hey/

//option
// 1. /^hey/ ==> text start by hey==>true
// 2. /hey$/ ==> text end by hey==>true
// 3. /^hey$/ ==> text exactly match ==>true
// 4. /^hey.*joe$/ ==> text start with hey and end with joe ==>true (.*)
// 5. /[a-z]/ ==> test range (/[a-z]/ or /[A-Z]/ or /[0-9]/)
// 6. combined Rang ==> [a-zA-Z0-9]
// 7. /[^0-9]/ ==> not number (^ in the first of bracets)
// 8. \d matches any digit, equivalent to [0-9]
// 9. \D matches any character that’s not a digit, equivalent to [^0-9]
// 10. \w matches any alphanumeric character, equivalent to [A-Za-z0-9]
// 11. \W matches any non-alphanumeric character, equivalent to [^A-Za-z0-9]
// 12. \s matches any whitespace character: spaces, tabs, newlines and Unicode spaces
// 13. \S matches any character that’s not a whitespace
// 14. \0 matches null
// 15. \n matches a newline character
// 16. \t matches a tab character
// 17. \uXXXX matches a unicode character with code XXXX (requires the u flag)
// 18. . matches any character that is not a newline char (e.g. \n) (unless you use the s flag, explained later on)
// 19. [^] matches any character, including newline characters. It’s useful on multiline strings.
// 20. /hey|ho/.test('hey') //✅ /hey|ho/.test('ho') //✅
// 21. + Match one or more (>=1) items ==> /^\d+$/.test('12') //✅ /^\d+$/.test('14') //✅ /^\d+$/.test('144343') //✅ 
// 22. * Match 0 or more (>= 0) items ==> /^\d*$/.test('12') //✅ /^\d*$/.test('14') //✅ /^\d*$/.test('144343') //✅ /^\d*$/.test('') //✅ 
// 23. {n} Match exactly n items ==>/^\d{3}$/.test('123') //✅ /^\d{3}$/.test('12') //❌ /^\d{3}$/.test('1234') //❌
// 24. {n,m} Match between n and m times
// 25. {3,} ==>/^\d{3,}$/.test('12') //❌ /^\d{3,}$/.test('123') //✅
// 26. ? ==>/^\d{3}\w?$/.test('123') //✅ /^\d{3}\w?$/.test('123a') //✅ /^\d{3}\w?$/.test('123ab') //❌
// 27. group ==>/^(\d{3})(\w+)$/.test('123') //❌ /^(\d{3})(\w+)$/.test('123s') //✅ /^(\d{3})(\w+)$/.test('123something') //✅ /^(\d{3})(\w+)$/.test('1234') //✅
// 28. group + ==> /^(\d{2})+$/.test('12') //✅ /^(\d{2})+$/.test('123') //❌ /^(\d{2})+$/.test('1234') //✅
// /\//         # slash
// /\\/         # backslash
// /\b/         # backspace
// /\e/         # escape
// /\f/         # form feed
// /\n/         # newline
// /\r/         # carriage return
// /\t/         # tab
// /\v/         # vertical tab
// /\NNN/       # octal ASCII character
// /\xNN/       # hexadecimal ASCII character
// /\x{FFFF}/   # hexadecimal unicode character
// /\x{10FFFF}/ # hexadecimal unicode character


