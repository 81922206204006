import React from 'react';
import { useSelector } from 'react-redux';
import { toPersianNum } from '../function/toPersian';

import styles from './pdfRender.module.css'





export const ComponentToPrint = React.forwardRef((props, ref) => {
    const azmoun=useSelector(state=>state.azmoun)
    const allData=useSelector(state=>state.userDetails)

    const renderPishAzmounMabhas=()=>{
        return azmoun.pishAzmounDetails.darsList.map((item,index)=>{
            let tempMabhas=""
            item.mabahes.forEach(ttt=>{
                tempMabhas+= ttt.titlename+" "+"----"+" "
            })
            return  <>
                        <div className={styles.section}>
                            <div className={styles.label}>درس {toPersianNum(index + 1)} :</div>
                            <div className={styles.content}>{item.dars.name}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}> مباحث:</div>
                            <div className={styles.content}>{tempMabhas}</div>
                        </div>
                    </>
    
        })
    }

    const renderOnkind=()=>{
        if(azmoun.azmounKind===1){
            return  <div ref={ref} style={{ width: "210mm", height: "297mm" ,direction:"rtl",paddingTop:"50px"}}>      
                        <div className={styles.section}>
                            <div className={styles.label}>نام دانش آموز :</div>
                            <div className={styles.content}>{allData.allData.Info.name_f +" "+allData.allData.Info.name_l}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}> نوع آزمون:</div>
                            <div className={styles.content}>آزمونک</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}>زمان شروع آزمون :</div>
                            <div className={styles.content}>{`${new Intl.DateTimeFormat('fa-IR-u-nu-latn', {timeStyle: 'medium'}).format(azmoun.startDate)} ${new Intl.DateTimeFormat('fa-IR-u-nu-latn').format(azmoun.startDate)} `}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}>زمان پایان آزمون :</div>
                            <div className={styles.content}>{`${new Intl.DateTimeFormat('fa-IR-u-nu-latn', {timeStyle: 'medium'}).format(azmoun.endDate)} ${new Intl.DateTimeFormat('fa-IR-u-nu-latn').format(azmoun.endDate)} `}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}> درس:</div>
                            <div className={styles.content}>{allData.allData.Lesson.find(item=>item.id===azmoun.azmounAkDetails.darsId).name}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}> مبحث:</div>
                            <div className={styles.content}>{allData.allData.Titeltest.find(item=>item.id===azmoun.azmounAkDetails.mabhasId).titlename}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}> سطح آزمون:</div>
                            <div className={styles.content}>{azmoun.azmounAkDetails.deficulty.name}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}> تعداد سوال:</div>
                            <div className={styles.content}>{toPersianNum(azmoun.azmounAkDetails.questionNumber)}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}> سوالات پاسخ داده شده:</div>
                            <div className={styles.content}>{toPersianNum(azmoun.answerNum)}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}> سوالات پاسخ داده نشده:</div>
                            <div className={styles.content}>{toPersianNum(azmoun.noAnsverNum)}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}>صحیح :</div>
                            <div className={styles.content}>{toPersianNum(azmoun.trueAnswerNum)}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}>غلط :</div>
                            <div className={styles.content}>{toPersianNum(azmoun.falseAnswerNum)}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}> درصد بدون احتساب نمره منفی:</div>
                            <div className={styles.content}>{toPersianNum((100/azmoun.azmounAkDetails.questionNumber)*(azmoun.trueAnswerNum))}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}> درصد با احتساب نمره منفی:</div>
                            <div className={styles.content}>{toPersianNum((((azmoun.trueAnswerNum*3)-(azmoun.falseAnswerNum))/(azmoun.azmounAkDetails.questionNumber*3))*100)}</div>
                        </div>
                    </div>
        }else if(azmoun.azmounKind===2){
            return  <div ref={ref} style={{ width: "210mm", height: "297mm" ,direction:"rtl",paddingTop:"50px"}}>      
                        <div className={styles.section}>
                            <div className={styles.label}>نام دانش آموز :</div>
                            <div className={styles.content}>{allData.allData.Info.name_f +" "+allData.allData.Info.name_l}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}> نوع آزمون:</div>
                            <div className={styles.content}>پیش آزمون</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}>زمان شروع آزمون :</div>
                            <div className={styles.content}>{`${new Intl.DateTimeFormat('fa-IR-u-nu-latn', {timeStyle: 'medium'}).format(azmoun.startDate)} ${new Intl.DateTimeFormat('fa-IR-u-nu-latn').format(azmoun.startDate)} `}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}>زمان پایان آزمون :</div>
                            <div className={styles.content}>{`${new Intl.DateTimeFormat('fa-IR-u-nu-latn', {timeStyle: 'medium'}).format(azmoun.endDate)} ${new Intl.DateTimeFormat('fa-IR-u-nu-latn').format(azmoun.endDate)} `}</div>
                        </div>
                        {renderPishAzmounMabhas()}
                        <div className={styles.section}>
                            <div className={styles.label}> تعداد سوال:</div>
                            <div className={styles.content}>{toPersianNum(azmoun.pishAzmounDetails.questionNumber)}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}> سوالات پاسخ داده شده:</div>
                            <div className={styles.content}>{toPersianNum(azmoun.answerNum)}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}> سوالات پاسخ داده نشده:</div>
                            <div className={styles.content}>{toPersianNum(azmoun.noAnsverNum)}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}>صحیح :</div>
                            <div className={styles.content}>{toPersianNum(azmoun.trueAnswerNum)}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}>غلط :</div>
                            <div className={styles.content}>{toPersianNum(azmoun.falseAnswerNum)}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}> درصد بدون احتساب نمره منفی:</div>
                            <div className={styles.content}>{toPersianNum((100/azmoun.pishAzmounDetails.questionNumber)*(azmoun.trueAnswerNum))}</div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.label}> درصد با احتساب نمره منفی:</div>
                            <div className={styles.content}>{toPersianNum((((azmoun.trueAnswerNum*3)-(azmoun.falseAnswerNum))/(azmoun.pishAzmounDetails.questionNumber*3))*100)}</div>
                        </div>
                    </div>
        }    
    }

    return (
      
        renderOnkind()
    );
  });