export const pages = (state={pageActive:false,pageDetails:{},lastPage:[0]}, action) => {
    switch (action.type) {
        case 'R4_PAGEACTIVE_ACTIVE':
            let lastPageTemp=state.lastPage;
            lastPageTemp.push(action.pageActive)
            return {pageActive:action.pageActive,pageDetails:action.pageDetails,lastPage:lastPageTemp}
        case 'R4_BACK_PAGE':
            if(state.lastPage.length>1){
                let popTemp=state.lastPage;
                return {pageActive:popTemp[popTemp.length-1],pageDetails:state.pageDetails,lastPage:popTemp}
            }
        default:
            return state;
            
    }
}
