import React, { useEffect, useState } from 'react';
import axios from 'axios';


//import redux moudle
import { useSelector } from 'react-redux';
import {  } from '../redux/action';


//component----------------------
import Login from './../1component-Login/Login';
import Page1 from '../4component-page1/Page1';
import Page2 from '../9component-page2/Page2';
import Page3 from '../10component-page3/Page3';
import Page4 from '../11component-page4/Page4';
import Loading from '../2component-Loading/Loadin';
import Alert from './../3component-alert/Alert';
import Azmoon from '../13component-azmoon/Azmoon';
import MyPejvok from '../14omponent-myPejvok/MyPejvok';
import Item2 from '../14omponent-myPejvok/item2/Item2';
import NotFound from '../NotFound/NotFound';
import ResultAzmoun from '../16Rcomponent-resultAzmoun/ResultAzmoun';
import EndAzmounAlert from '../17component-endAzmounAlert/EndAzmounAlert';
import OtherPrint from '../20component-otherPrint/OtherPrint';
import ReportErorr from './../21component-reportErorr/ReportErorr'



//=====================================================
//=====================================================
//=====================================================
//component start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function MainPage (){
    



//=====================================================
// rendering pages---------------------------------
//=====================================================
const pageActive=useSelector(state=>state.pages)

const renderingPages=()=>{

            if(pageActive.pageActive===-1){
                return <Login/>
            }else if(pageActive.pageActive===0){
                return <Page1/>
            }else if(pageActive.pageActive===1){
                return <Page2/>
            }else if(pageActive.pageActive===2){
                return <MyPejvok/>
            }else if(pageActive.pageActive===3){
                return <Page3/>
            }else if(pageActive.pageActive===4){
                return <Page4/>
            }else if(pageActive.pageActive===5){
                return <Azmoon/>
            }else if(pageActive.pageActive===false){
                return <Loading/>
            }else{
            return  <NotFound/>
            }




}

//=====================================================
// loading---------------------------------
//=====================================================
const loadinStatus=useSelector(state=>state.loading)
const renderLoading=()=>{
    if(loadinStatus){
        return <Loading/>
    } 
}
//=====================================================
// reportError---------------------------------
//=====================================================
const erorrStatus=useSelector(state=>state.reportErorr)
const renderErorr=()=>{
    if(erorrStatus.active){
        return <ReportErorr/>
    } 
}
//=====================================================
// render Alert function---------------------------------
//=====================================================
const AlertStatue=useSelector(state=>state.alert.status)
const renderAlert=()=>{
    if(AlertStatue){
        return <Alert/>
    } 
}
//=====================================================
// render Alert function---------------------------------
//=====================================================
const printDetails=useSelector(state=>state.printDetails.active)
const renderPrint=()=>{
    if(printDetails){
        return <OtherPrint/>
    } 
}
//=====================================================
// render profile edit form---------------------------------
//=====================================================
const profileEdit=useSelector(state=>state.detailsEditForm.status)
const renderProfileEdit=()=>{
    if(profileEdit){
        return <Item2/>
    } 
}
//=====================================================
// render show Result AfterAzmoun---------------------------------
//=====================================================
const azmoun=useSelector(state=>state.azmoun)
const renderShowResultAfterAzmoun=()=>{
    if(azmoun.showResult){
        return <ResultAzmoun/>
    } 
}
//=====================================================
// render end azmoun alert---------------------------------
//=====================================================
const endAzmounAlert=useSelector(state=>state.endAzmounAlert)
const renderEndAzmounAlert=()=>{
    if(endAzmounAlert.status){
        return <EndAzmounAlert/>
    } 
}

//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <>
            {renderLoading()}
            {renderAlert()}
            {renderProfileEdit()}
            {renderingPages()}
            {renderShowResultAfterAzmoun()}
            {renderEndAzmounAlert()}
            {renderPrint()}
            {renderErorr()}
        </>
    );

}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================