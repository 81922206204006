import React from 'react';
import style from './box5.module.css'
//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================

//=====================================================
export default function Box5() {

    //=====================================================
    // render of component---------------------------------
    //=====================================================
        return(
            <div className={style.detailsItem3}>
                <div className={style.Qcolor1}>
                    <div className={style.color}></div>
                    <div className={style.text}>پاسخ داده ام</div>
                </div>
                <div className={style.Qcolor2}>
                    <div className={style.color}></div>
                    <div className={style.text}>نمی خواهم پاسخ دهم</div>
                </div>
                <div className={style.Qcolor3}>
                    <div className={style.color}></div>
                    <div className={style.text}>بعدا پاسخ می دهم</div>
                </div>
            </div>
    
        )
    }
    //=====================================================
    //=====================================================
    //=====================================================
    //component end -------------------------------------
    //=====================================================
    //=====================================================
    //=====================================================