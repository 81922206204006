import React, { useState,useRef } from 'react'
//redux
import {useDispatch, useSelector} from 'react-redux'
//import Styles
import style from './page4.module.css'
//component
import CurentTime from '../5component-CurentTime/CurentTime';
import UserDetails from '../6component-userDetails/UserDetails';
import DailyText from '../7component-dailyText/DailyText';
import PageKey from '../8component-pageKey/PageKey';
import PishAzmoonSelect from '../12component-pishAzmoonSelect/PishAzmoonSelect';
//import Images
import img1 from './../img/page4BG1.png'
import img2 from './../img/page2BG2.png'
import img3 from './../img/page2BG3.png'
import SelectedItem from './selectedItem/SelectedItem';
import { R10openPishAzmounSelect ,R3loading,R2alert,R5setKindAzmoun,R5startAzmoun,R5setAzmoun,R4pages} from '../redux/action';


// axios
import axios from 'axios';


//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function Page4() {
// //=====================================================
// // taggole button---------------------------------
// //=====================================================
// const[taggoleStatus,setTaggoleStatus]=useState(false)
// const taggoleFun=()=>{
//     setTaggoleStatus(!taggoleStatus)
// }
//=====================================================
// render pishazmoun select---------------------------------
//=====================================================
const statusSelect=useSelector(state=>state.openPishAzmounSelect)
const renderSelect=()=>{
    if(statusSelect){
        return <PishAzmoonSelect/>
    }
}
//=====================================================
// open select btn---------------------------------
//=====================================================
const dispatch=useDispatch()
const openSelectFunc=()=>{
    if(listSelect.listEnd.length<6){
        dispatch(R10openPishAzmounSelect())
    }else{
        dispatch(R2alert(true,"شما نهایتا ۶ درس می توانید انتخاب کنید."))
    }
    
}
//=====================================================
// render selected---------------------------------
//=====================================================
const listSelect=useSelector(state=>state.tempPishazmoun)
let tempEndSend=[];
let questionNumberTemp=0;
listSelect.listEnd.forEach(item=>{
    let darsTemp=item.dars.id;
    let partTemp=[];
    item.mabahes.forEach(www=>{
        partTemp.push({id:www.titleid})
    })
    tempEndSend.push({
       lesson_id:darsTemp,
       part:partTemp,
       count:item.number
    })
    questionNumberTemp+=item.number
})
const renderSelected=()=>{
    return listSelect.listEnd.map((item , index)=>{
                return <SelectedItem key={index} number={index+1} details={item}></SelectedItem>
            })
}
//=====================================================
// render selected---------------------------------
//=====================================================
const allData=useSelector(state=>state.userDetails)
const createPishAzmounFunc=()=>{
    
    let templisttt=JSON.stringify(tempEndSend)
    dispatch(R3loading(true))
    if(listSelect.listEnd.length>0){
        console.log(templisttt)
        axios({
            method:'post',
            url:'https://pejvak-konkur.ir/studentrest/createpretest',
            data:{
                tel:allData.allData.Info.tel,
                nawstr:"naw%a_m",
                list:templisttt,
                
            }
        })
        .then(function (response) {
            console.log(response.data)
            if(response.data===101){
                dispatch(R3loading(false))
                dispatch(R2alert(true,"11خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
            }else if(response.data===106){
                dispatch(R3loading(false))
                dispatch(R2alert(true,"شما امروز تعداد آزمونک های مجاز خود را استفاده کرده اید"))
            }else if(response.data===110){
                dispatch(R3loading(false))
                dispatch(R2alert(true,"از مباحث انتخابی هیچ سوالی برای ساخت آزمون موجود نیست"))
            }else{
                dispatch(R3loading(false))
                dispatch(R5setKindAzmoun(2,{darsList:listSelect.listEnd,
                                                questionNumber:questionNumberTemp,
                                                timeStatus:taggoleStatus,
                                                timeD:timeInMinute
                                            },{},{}));
                dispatch(R5startAzmoun(new Date()))
                dispatch(R5setAzmoun(response.data.test))
                dispatch(R4pages(5,""))
            }
    
            })
        .catch((error)=>{
            dispatch(R3loading(false))
            dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید11233."))
        })
    }

    else{
        dispatch(R2alert(true,"شمار درسی انتخاب نکرده اید!!"))
        dispatch(R3loading(true))
    }


}

//=====================================================
// taggole button---------------------------------
//=====================================================
const[taggoleStatus,setTaggoleStatus]=useState(false)
const taggoleFun=()=>{
    setTaggoleStatus(!taggoleStatus)
}
//=====================================================
// renderTimeInput---------------------------------
//=====================================================
const azmunTimeRef=useRef()
const renderTimeInput=()=>{
    if(taggoleStatus===true){
        return  <div className={style.selectContain}>
                    <label htmlFor="chdoshvari">زمان را وارد کنید :</label>
                    <div className={style.item2}>
                        <input type="number" name="" ref={azmunTimeRef} className={style.numberInput}  placeholder="زمان را به دقیق وارد کنید" onChange={getTimeOfAzmunFunc}/>
                    </div>
                </div>
    }

}
let timeInMinute=0;
const getTimeOfAzmunFunc=()=>{
    timeInMinute=azmunTimeRef.current.value;
}

//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.Container1} style={{ backgroundImage: `url(${img1})` }}>
            {renderSelect()}
            <div className={style.ContainerOver}>
                <div className={style.Container2} >
                    <div className={style.headerContain}>
                        <div className={style.right}>
                            <CurentTime/>
                            <UserDetails/>
                        </div>
                        <div className={style.left}>
                            <DailyText/>
                            <PageKey/>
                        </div>
                    </div>
                    <div className={style.contentContain}>
                        <div className={`${style.contentLeft} animate__animated animate__lightSpeedInRight`} >
                            <h1>پیش آزمون</h1>
                            <p>
                            در این بخش می توانید به راحتی برای هر مبحث جزء از هر درس یا هر بخش از هر کتاب که مطالعه نموده،آزمونی با ویژگی های یک آزمون کامل ایجاد نموده تا علاوه بر تمرین تست زنی با انواع تست های آن مبحث مورد مطالعه آشنا و همچنین نوع و میزان تسلط خود برای آن مبحث را نیز مورد سنجش قرار دهید.                            </p>
                            <div className={style.startPishAzmoon} onClick={createPishAzmounFunc}>شروع آزمون</div>
                        </div>
                        <div className={style.contentRight} >
                            <div className={style.mainLabel}>ساخت پیش آزمون</div>
                            <button onClick={openSelectFunc}>اضافه کردن دروس پیش آزمون</button>
                            <div className={style.selectedCourse}>
                                {renderSelected()}
                            </div>
                            <div className={style.selectContain}>
                                <label htmlFor="chdoshvari">زمان آزمون :</label>
                                <div className={style.taggoleContain}>
                                    <div className={`${style.buttonOfTaggole}  ${taggoleStatus?style.taggoleActive:style.taggoleDeActive}`} onClick={taggoleFun}></div>
                                </div>
                            </div>
                            {renderTimeInput()}
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================