import React from 'react';
//redux
import {useDispatch,useSelector} from 'react-redux'
import {R2alert,R3loading} from './../redux/action';

//import Styles
import style from './alert.module.css'


//=====================================================
//=====================================================
//=====================================================
//component start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function Alert() {

const textOfAlert=useSelector(state=>state.alert.text)
//=====================================================
// ok alert function---------------------------------
//=====================================================
const dispatch=useDispatch()
const okAlert=()=>{
    dispatch(R2alert(false,''))
    dispatch(R3loading(false))
}
//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.Container} >
            <div className={`${style.alertContainer} animate__animated animate__bounceInRight`}>
                <div className={style.text}>{textOfAlert}</div>
                <div className={style.button} onClick={okAlert}>!متوجه شدم</div>
            </div>
        </div>
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================