import React,{useState,useEffect} from 'react';
//redux
import {useDispatch,useSelector} from 'react-redux'
import {R5showResult, R9endAzmounAlert,R3loading,R4pages,R2alert,R5serverResult} from './../redux/action';

//import Styles
import style from './endAzmounAlert.module.css'

//axios
import axios from 'axios';
//=====================================================
//=====================================================
//=====================================================
//component start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function EndAzmounAlert() {

const endAzmounAlert=useSelector(state=>state.endAzmounAlert)

const azmoun=useSelector(state=>state.azmoun)

//=====================================================
// ok alert function---------------------------------
//=====================================================
const dispatch=useDispatch()
const okAlert=()=>{
    if(azmoun.azmounKind===1){
        setTimeout(() => {
            sendResultToServer1()
        }, 2000);
    }else if(azmoun.azmounKind===2){
        setTimeout(() => {
            sendResultToServer2()
        }, 2000);
    }

    dispatch(R9endAzmounAlert(false,'',true))
}
const notOkAlert=()=>{
    dispatch(R3loading(false))
    dispatch(R9endAzmounAlert(false,'',false))
}
//=====================================================
// send to server---------------------------------
//=====================================================
const [oktosenddata,setOktosenddata]=useState(false)
const allData=useSelector(state=>state.userDetails)
const sendResultToServer1=()=>{
    if(azmoun.sendToServerList.length>0){
        let templisttt=JSON.stringify(azmoun.sendToServerList)
        axios({
            
            method:'post',
            url:'https://pejvak-konkur.ir/studentrest/resultshorttest',
            data:{
                tel:allData.allData.Info.tel,
                nawstr:"naw%a_m",
                lesson_id:allData.allData.Lesson.find(item=>item.id===azmoun.azmounAkDetails.darsId).id,
                part:allData.allData.Titeltest.find(item=>item.id===azmoun.azmounAkDetails.mabhasId).titleid,
                list:templisttt,
                total_exam:azmoun.azmounAkDetails.questionNumber,
                time_start:azmoun.startDate,
                time_end:azmoun.endDate,
                number_false:azmoun.falseAnswerNum,
                number_true:azmoun.trueAnswerNum,
                number_unselect:azmoun.noAnsverNum,
                score_negative:`${(((azmoun.trueAnswerNum*3)-(azmoun.falseAnswerNum))/(azmoun.azmounAkDetails.questionNumber*3))*100}%`,
                score:`${(100/azmoun.azmounAkDetails.questionNumber)*(azmoun.trueAnswerNum)}% `,
            }
        })
        .then(function (response) {
            if(response.data!==101){
                dispatch(R5serverResult(response.data))
                dispatch(R3loading(false))
                dispatch(R5showResult())
                dispatch(R4pages(0,""))
                
            }else{
                dispatch(R3loading(false))
                dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
            }
            
            console.log(response.data)

                    
                })
        .catch((error)=>{
            dispatch(R3loading(false))
            dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
            
        })
    }else{
        setOktosenddata(true)
    }

}

//=====================================================
// send to server---------------------------------
//=====================================================
const sendResultToServer2=()=>{
    if(azmoun.sendToServerList.length>0){
        let templisttt=JSON.stringify(azmoun.sendToServerList)
        console.log({
            tel:allData.allData.Info.tel,
            nawstr:"naw%a_m",
            // lesson_id:allData.allData.Lesson.find(item=>item.id===azmoun.azmounAkDetails.darsId).id,
            // part:allData.allData.Titeltest.find(item=>item.id===azmoun.azmounAkDetails.mabhasId).titleid,
            list:templisttt,
            total_exam:azmoun.pishAzmounDetails.questionNumber,
            time_start:azmoun.startDate,
            time_end:azmoun.endDate,
            number_false:azmoun.falseAnswerNum,
            number_true:azmoun.trueAnswerNum,
            number_unselect:azmoun.noAnsverNum,
            score_negative:`${(((azmoun.trueAnswerNum*3)-(azmoun.falseAnswerNum))/(azmoun.pishAzmounDetails.questionNumber*3))*100}%`,
            score:`${(100/azmoun.pishAzmounDetails.questionNumber)*(azmoun.trueAnswerNum)}% `,
        })

        
        axios({
            
            method:'post',
            url:'https://pejvak-konkur.ir/studentrest/resultpretest',
            data:{
                tel:allData.allData.Info.tel,
                nawstr:"naw%a_m",
                // lesson_id:allData.allData.Lesson.find(item=>item.id===azmoun.azmounAkDetails.darsId).id,
                // part:allData.allData.Titeltest.find(item=>item.id===azmoun.azmounAkDetails.mabhasId).titleid,
                list:templisttt,
                total_exam:azmoun.pishAzmounDetails.questionNumber,
                time_start:azmoun.startDate,
                time_end:azmoun.endDate,
                number_false:azmoun.falseAnswerNum,
                number_true:azmoun.trueAnswerNum,
                number_unselect:azmoun.noAnsverNum,
                score_negative:`${(((azmoun.trueAnswerNum*3)-(azmoun.falseAnswerNum))/(azmoun.pishAzmounDetails.questionNumber*3))*100}%`,
                score:`${(100/azmoun.pishAzmounDetails.questionNumber)*(azmoun.trueAnswerNum)}% `,
            }
        })
        .then(function (response) {
            
            if(response.data!==101){
                dispatch(R5serverResult(response.data))
                dispatch(R3loading(false))
                dispatch(R5showResult())
                dispatch(R4pages(0,""))
                
            }else{
                dispatch(R3loading(false))
                dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
            }
            
            console.log(response.data)

                    
                })
        .catch((error)=>{
            dispatch(R3loading(false))
            dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
            
        })
    }else{
        setOktosenddata(true)
    }

}
useEffect(()=>{

    if(oktosenddata){
        if(azmoun.azmounKind===1){
            sendResultToServer1()
            setOktosenddata(false)
        }else if(azmoun.azmounKind===2){
            sendResultToServer2()
            setOktosenddata(false)
        }
    }
},[oktosenddata])
//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.Container} >
            <div className={`${style.alertContainer} animate__animated animate__bounceInRight`}>
                <div className={style.text}>{endAzmounAlert.text}</div>
                <div className={style.btnContainer}>
                    <div className={style.button} onClick={okAlert}>بله</div>
                    <div className={style.button2} onClick={notOkAlert}>انصراف</div>
                </div>
            </div>
        </div>
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================