
import React from 'react'
import "./NotFound.css"


function NotFound(){




    return(
        <div className="nc">
            <div>
            صفحه ی مورد نظر یافت نشد!
            </div>
        </div>
        
    )
}

export default NotFound;