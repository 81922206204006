import React from 'react'
//moment
import moment from 'moment';
//import redux moudle
import {useDispatch,useSelector } from 'react-redux';
import {} from '../../redux/action';
//import to Persian
import {toPersianNum} from '../../function/toPersian'
//import Styles
import style from './item3.module.css'

//font AwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUserAlt} from "@fortawesome/free-solid-svg-icons"
import Alert from '../../3component-alert/Alert'





//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function Item3() {

//=====================================================
// get data---------------------------------
//=====================================================
const allData=useSelector(state=>state.userDetails)
//=====================================================
// calculate days of account---------------------------------
//=====================================================
var start = moment().format("YYYY-MM-DD");
// var start = moment(allData.allData.Info.start_date, "YYYY-MM-DD");
var end = moment(allData.allData.Info.end_date, "YYYY-MM-DD");

//Difference in number of days
let daysOfAccount=moment.duration(end.diff(start)).asDays();


//=====================================================
// render of component---------------------------------
//=====================================================
    return(
 
            
            <div className={style.container}>
                {/* <div className={style.banerContainer}>
                    <img className={style.banerPic} src="" alt="بنر طرح"/>
                </div> */}
                {allData.allData.Info.status===1?<>
                    <div className={style.acountContainer}>
                        <div className={style.itemsContain}>
                            <div className={style.label}>نوع حساب:</div>
                            <div className={style.content}>{allData.allData.Info.name}</div>
                        </div>
                        <div className={style.itemsContain}>
                            <div className={style.label}>مدت اعتبار:</div>
                            <div className={style.content}>{toPersianNum(daysOfAccount)} روز</div>
                        </div>
                    </div>
                    <div className={style.azmunakContainer}>
                        <h1>آزمونک</h1>
                        <div className={style.itemsContain}>
                            <div className={style.label}> تعداد کل:</div>
                            <div className={style.content}>{allData.allData.Info.number_short_test}</div>
                        </div>
                        <div className={style.itemsContain}>
                            <div className={style.label}>باقی مانده:</div>
                            <div className={style.content}>{allData.allData.Info.leftover_shorttest}</div>
                        </div>
                    </div>
                    <div className={style.pishazmunContainer}>
                        <h1>پیش آزمون</h1>
                        <div className={style.itemsContain}>
                            <div className={style.label}> تعداد کل:</div>
                            <div className={style.content}>{allData.allData.Info.number_pre_test}</div>
                        </div>
                        <div className={style.itemsContain}>
                            <div className={style.label}>باقی مانده:</div>
                            <div className={style.content}>{allData.allData.Info.leftover_pertest}</div>
                        </div>
                    </div>
                    <div className={style.nightTestContainer}>
                        <h1>تست شبانه</h1>
                        <div className={style.itemsContain}>
                            <div className={style.label}> تعداد کل:</div>
                            <div className={style.content}>{allData.allData.Info.number_test}</div>
                        </div>
                        <div className={style.itemsContain}>
                            <div className={style.label}>باقی مانده:</div>
                            <div className={style.content}>{allData.allData.Info.leftovertest}</div>
                        </div>
                    </div>
                    <div className={style.moshaverehContainer}>
                        <h1>مشاوره</h1>
                        <div className={style.itemsContain}>
                            <div className={style.label}>نام مشاور:</div>
                            <div className={style.content}>محمد نذری</div>
                        </div>
                        <div className={style.itemsContain}>
                            <div className={style.label}>اعتبار:</div>
                            <div className={style.content}>روز ۲۰</div>
                        </div>
                    </div>
                </>:<div className={style.noAcount}>اعتبار حساب شما به پایان رسیده برای استفاده مجدد اقدام به خریداری طرح نمایید</div>}

                <div className={style.buyAccount}>
                    <div className={style.buyAccountBtn}>خرید طرح</div>
                </div>
            </div>



    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================