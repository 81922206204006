import React from 'react'
//redux
//redux
import {useDispatch} from 'react-redux'
import { R4pages,R4backPage } from './../redux/action';
//import Styles
import style from './pageKey.module.css'
//font AwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faHouseUser,faHome,faArrowLeft} from "@fortawesome/free-solid-svg-icons"






//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function PageKey() {
//=====================================================
// go to  my Pejvok---------------------------------
//=====================================================
const dispatch=useDispatch()
const openMyPejvokFunc=()=>{
    dispatch(R4pages(2,""))
}
//=====================================================
// go to  home---------------------------------
//=====================================================
const openHomeFunc=()=>{
    dispatch(R4pages(0,""))
}
//=====================================================
// back---------------------------------
//=====================================================
const backFunc=()=>{
    dispatch(R4backPage())
}
//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.pageKeyContainer}>
            <div className={style.keyPageItem} title="پژواک من" onClick={openMyPejvokFunc}>
                <FontAwesomeIcon  icon={faHouseUser} className={style.iconsKey} />
            </div>
            <div className={style.keyPageItem} title="خانه" onClick={openHomeFunc}>
                <FontAwesomeIcon  icon={faHome} className={style.iconsKey}  />
            </div>
            <div className={style.keyPageItem} title="عقب" onClick={backFunc} >
                <FontAwesomeIcon  icon={faArrowLeft} className={style.iconsKey} />
            </div>
        </div>
    )
 }
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================