import React from 'react'
import Form1 from './Form1/Form1'
import Form2 from './Form2/Form2'
import Form3 from './Form3/Form3'
import Form4 from './Form4/Form4'
import Form5 from './Form5/Form5'
import Form6 from './Form6/Form6'
//redux
import {useSelector} from 'react-redux'
//import Styles
import style from './login.module.css'

//import Images
import img1 from './../img/login.jpg'







//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function Login() {



//=====================================================
//activeForm-------------------------------------------
//=====================================================
const ReduxLoginForm=useSelector(state=>state.loginForm)
//function render form active
const renderFormActive=()=>{

    if(ReduxLoginForm.formActive===0){
        return <Form1/>
    }else if(ReduxLoginForm.formActive===1){
        return <Form2/>
    }else if(ReduxLoginForm.formActive===2){
        return <Form3/>
    }else if(ReduxLoginForm.formActive===3){
        return <Form4/>
    }else if(ReduxLoginForm.formActive===4){
        return <Form5/>
    }else if(ReduxLoginForm.formActive===5){
        return <Form6/>
    }
}


//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.Container1} >
            <div className={style.LoginContainer} style={{ backgroundImage: `url(${img1})` }}>
                {renderFormActive()}
            </div> 
        </div>
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================