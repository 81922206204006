import React from 'react';
import style from './box3a.module.css'
//redux
import {useSelector} from 'react-redux'

//function
import { toPersianNum } from '../../function/toPersian';
//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================

//=====================================================
export default function Box3a() {
    const azmoun=useSelector(state=>state.azmoun)
    const allData=useSelector(state=>state.userDetails.allData)
    //=====================================================
    // render of component---------------------------------
    //=====================================================
        return(
        azmoun.azmounKind===1?<div className={style.detailsItem3}>
                                    <div className={style.kindazmunContain}>
                                        <div className={style.content}>آزمونک</div>
                                    </div>
                                    {/* <div className={style.detailsazmunContain}>
                                        <div className={style.label}>پایه تحصیلی :</div>
                                        <div className={style.content}>دهم</div>
                                    </div> */}
                                    <div className={style.detailsazmunContain}>
                                        <div className={style.label}>درس :</div>
                                        <div className={style.content}>{allData.Lesson.find(item=>item.id===azmoun.azmounAkDetails.darsId).name}</div>
                                    </div>
                                    <div className={style.detailsazmunContain}>
                                        <div className={style.label}>مبحث :</div>
                                        <div className={style.content}>{allData.Titeltest.find(item=>item.id===azmoun.azmounAkDetails.mabhasId).titlename}</div>
                                    </div>
                                    <div className={style.detailsazmunContain}>
                                        <div className={style.label}>تعداد سوال:</div>
                                        <div className={style.content}>{toPersianNum(azmoun.azmounAkDetails.questionNumber)}</div>
                                    </div>
                                    <div className={style.detailsazmunContain}>
                                        <div className={style.label}>سطح سوالات :</div>
                                        <div className={style.content}>{azmoun.azmounAkDetails.deficulty.name}</div>
                                    </div>
                                </div>:""

        )
    }
    //=====================================================
    //=====================================================
    //=====================================================
    //component end -------------------------------------
    //=====================================================
    //=====================================================
    //=====================================================