import React, { useState,useRef,useEffect } from 'react'

//import Styles
import style from './selectList.module.css'

//font AwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleDown,faPlusSquare} from "@fortawesome/free-solid-svg-icons"






//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function SelectList(props) {
//=====================================================
//temp State---------------------------------
//=====================================================
const [activeItem,setActiveItem]=useState({name:"notSet"})
useEffect(()=>{
    if(props.List!==undefined)
    setActiveItem(props.List.find(item=>item.active===true))
    props.sendSelectedItem(props.List.find(item=>item.active===true))
},[])

//=====================================================
//openList---------------------------------
//=====================================================
const [openList,setOpenList]=useState(false)
const listRef=useRef()
const openListFunc=()=>{
    if(!openList){
        listRef.current.style.display="flex"
        setOpenList(true)
    }
    else{
        listRef.current.style.display="none"
        setOpenList(false)
    }
}
const listRef2=useRef()
useEffect(() => {
    function handleClickOutside(event) {
      if (listRef2.current && !listRef2.current.contains(event.target)) {
            listRef.current.style.display="none"
            setOpenList(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openList]);

//=====================================================
// render List Item---------------------------------
//=====================================================
const renderList=()=>{

   return props.List.map(item => <div className={style.listItem} key={item.id} title={item.name} onClick={()=>selectItemDoFunc(item)}>{item.name}</div>);

}
//=====================================================
// select Item---------------------------------
//=====================================================
const selectItemDoFunc=(item)=>{
    setActiveItem(item)
    props.sendSelectedItem(item)
    listRef.current.style.display="none"
}

//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.selectListContain} ref={listRef2}>
            <div className={style.inputContain} onClick={openListFunc}>
                <div className={style.selectedLabel}><p>{activeItem.name}</p></div>
                <div className={style.downIcon}>
                    <FontAwesomeIcon  icon={faAngleDown} size="1x" /> 
                </div>
            </div>
            <div className={style.listContain} ref={listRef}>
                {renderList()}
            </div>
        </div>
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================